import React, { useEffect, useState } from 'react';
import ReactDOm from 'react-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoginImg from '../../assets/images/side-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { loginAction, beforeLogin, getUserCart } from "./login.action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ENV } from "../../config/config";
import { getVATforCountry, getSettings } from "../siteSettings/siteSettings.action";
import { beforeCart, addToUserCart } from '../cart/cart.action'

import LocalizedStrings from "react-localization";
import localizations from '../../localization';

const Login = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language')?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(()=>{
		if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
		  translations.setLanguage(localStorage.getItem('language'));
		  setLang(localStorage.getItem('language'))
		}
	},[localStorage.getItem('language')])

	const history = useHistory();
	const [value, setValue] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [userData, setUserData] = useState({
		email: "",
		password: ""
	});
	const [captcha, setCaptcha] = useState('')

	const [msg, setMsg] = useState({
		email: '',
		passowrd: '',
		captcha: ''
	})

	const login = (e) => {
		e.preventDefault();
		let payload = {
			email: userData.email,
			password: userData.password
		};
		if (!captcha || !userData.email || !userData.password ) {
			setMsg({
				email: !userData.email ? translations['Email is required'] : '',
				passowrd: !userData.password ? translations['Password is required'] : '',
				captcha: !captcha ? translations['Prove you are a human'] : ''
			})
		}
		else {
			setMsg({
				email: '',
				passowrd: '',
				captcha: ''
			})
			props.loginAction(payload);

		}
	}



	useEffect(() => {
		if (props.login.loginActionAuth) {
			let userData = ENV.getUserKeys();
			props.getUserCart(userData._id)
			
		}
	}, [props.login.loginActionAuth])


	useEffect(() => {
		if (props.login.cartAuth) {
		
			if(props.login.cart){
				ENV.setLocalCart(props.login.cart)
			}

			let userData = ENV.getUserKeys();
			let cartParams = {
				emailLanguage : localStorage.getItem('language'),
				userId: userData._id,
				isCheckout: false,
				items: JSON.parse(localStorage.getItem("cart")),
			}
			props.addToUserCart(cartParams)
			props.beforeCart()
			
			props.beforeLogin()
			history.push(`/${localStorage.getItem('language')}`);
			
		}
	}, [props.login.cartAuth])

	useEffect(() => {
		if (props.cart.cartAddedAuth) {

			props.beforeCart()
			
			props.beforeLogin()
		}

	}, [props.cart.cartAddedAuth]);


	const showPasswordMethod = (e) => {
		e.preventDefault();
		setShowPassword(!showPassword)
	}

	function onChange(value) {
		setCaptcha(value)
	}
	function onExpired(value) {
		setCaptcha('')
	}

	return (
		<section className="wrapper account-verification">
			<Container fluid>
			<Form onSubmit={(e) => login(e)}>
				<Row className="align-items-center">
					<Col lg="4" md="4" xs="12" className="pl-0 pr-0">
						<div className="form-side-display d-flex justify-content-center align-items-center">
							<Link to={`/${lang}`} className="d-inline-block">
								<img src={LoginImg} alt="logo" className="img-fluid" />
							</Link>
						</div>
					</Col>
					<Col lg="8" md="8" xs="12" className="pl-0 pr-0 ">
						<div className="login-main new-logged-des">
							<div className="login-main-child">
								<h6>{translations['Welcome Back']}</h6>
								<h2>{translations['Login to your account']}</h2>
								<form className="login-form-main">
									<div className="mb-3 login-form-input">
										<input type="email" className="form-control" placeholder={`name@domain.com `+translations['(Required)']} id="exampleInputEmail1" aria-describedby="emailHelp"
											value={userData.email}      autoFocus ={true}
											onChange={(e) => setUserData({ ...userData, email: e.target.value })} />

										{msg.email && <small className="error">{msg.email}</small>}
									</div>
									<div className="mb-3 login-form-input form-password-eye-box">
										<input type={showPassword ? "text" : "password"} className="form-control" placeholder={translations['Password']+` `+translations['(Required)']} id="exampleInputPassword1"
											value={userData.password}
											onChange={(e) => setUserData({ ...userData, password: e.target.value })}
										/>
										<a onClick={(e) => showPasswordMethod(e)} className="form-password-eye">
											<FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
										</a>
										{msg.passowrd && <small className="error">{msg.passowrd}</small>}
									</div>
									<div className="mb-4 form-check pl-0 d-flex justify-content-between">
										<div className="mb-4 form-check d-flex justify-content-between custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="defaultChecked" />
											<label class="custom-control-label remember-me-checkbox" for="defaultChecked">{translations['Remember Me']}</label>
										</div>
										<Link to={"/forget-password/"+lang} id="forget-password">{translations['Forget Password?']}</Link>
									</div>
									<div className="row align-items-center recaptcha-btn-wrap ">
                                        <div className="col-lg-6 col-md-12">
											<div className="g-recaptcha">
												<ReCAPTCHA
													sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
													onChange={onChange}
													onExpired={onExpired}
												/>
												{msg.captcha && <small className="error">{msg.captcha}</small>}
											</div>
										</div>
										<div className="col-lg-6 col-md-12">
											{/* {msg.captcha && <small className="error">{msg.captcha}</small>} */}
											<div className="contact-us-main-btn">
												<button onClick={(e) => login(e)} className="main-btn d-inline-block">{translations['Sign In']}</button>
											</div>
										</div>
									</div>
									<div>
										<Link to={`/register/${lang}`} id="register-new-user">{translations['Register As New Customer']}</Link>
									</div>
								</form>
							</div>
						</div>
					</Col>
				</Row>
				</Form>
			</Container>
		</section>
	)
}


const mapStateToProps = state => ({
	errors: state.errors,
	siteSetting: state.siteSetting,
	login: state.login,
	cart: state.cart,
})
export default connect(mapStateToProps, { beforeLogin,beforeCart ,addToUserCart, loginAction, getUserCart, getVATforCountry, getSettings })(Login);