import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import './templateabout.css'

const TemplateAbout = () => {
    return (
        <div className='wrapper'>
            <Header />
            <div className='template-wrapper template-about-wrapper'>
                <div className='template-banner'>
                    <div className='container-box'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-lg-7'>
                                <div className='template-banner-content'>
                                    <h2>ABOUT US</h2>
                                    <h5>WE CYBER-GUARD YOUR DATA, DEVICES & E-COMMUNICATIONS</h5>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='template-banner-img'>
                                    <img src='https://attivita.arhamsoft.org/static/media/about-us-banner.564cd2ab.png' alt="banner-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-main-section'>
                    <div className='container-box'>
                        <div className='about-main-section-heading'>
                            <h3>Lorem ipsum</h3>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>
                        </div>
                        <div className='template-boxes'>
                            <div className='row'>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Lorem ipsum</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Kerop Kosle</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Lofsak Oprkaooo</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Massive Lakdoo</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-video-section'>
                <div className='container-box'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-6'>
                            <div className='about-video-section-content'>
                                <h3>Lorem Ipsum</h3>
                                <h6>Lorem ipsum dolor sit amet, consetetur sadipscing elitr</h6>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, </p>
                                <p>Yo sea takimata sanctus est Lorem ipsum dolor sit amdcet eorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonu.</p>
                                <button className='btn-blue'>Lorem Boasle</button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='template-info-video'>
                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/B-ytMSuwbf8" title="YouTube video player" frameborder="0" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='template-about-listing'>
                <div className='container-box'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-6'>
                            <div className='template-info-img'>
                                <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='template-about-listing-content'>
                                <div className='template-about-listing-img'>
                                    <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='list-img' />
                                </div>
                                <div className='template-about-listing-info'>
                                    <h4>Lorem ipsum</h4>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                                </div>
                            </div>
                            <div className='template-about-listing-content'>
                                <div className='template-about-listing-img'>
                                    <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='list-img' />
                                </div>
                                <div className='template-about-listing-info'>
                                    <h4>Lorem ipsum</h4>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                                </div>
                            </div>
                            <div className='template-about-listing-content'>
                                <div className='template-about-listing-img'>
                                    <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='list-img' />
                                </div>
                                <div className='template-about-listing-info'>
                                    <h4>Lorem ipsum</h4>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                                </div>
                            </div>
                            <div className='template-about-listing-content'>
                                <div className='template-about-listing-img'>
                                    <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='list-img' />
                                </div>
                                <div className='template-about-listing-info'>
                                    <h4>Lorem ipsum</h4>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='template-form-wrapper template-about-form'>
                <div className='section-heading'>
                    <h1>Lorem ipsum</h1>
                    <h6>Lorem ipsum dolor sit amet</h6>
                </div>
                <div className='container'>
                    <form>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="mb-4 input-wrapper">
                                    <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="mb-4 input-wrapper">
                                    <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="mb-4 input-wrapper">
                                    <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="mb-4 input-wrapper">
                                    <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="mb-4 input-wrapper">
                                    <textarea className="form-control" placeholder="Lorem ipsum" id="floatingTextarea2" rows="5"></textarea>
                                </div>
                            </div>
                            <div className='form-button'>
                                <button type="submit" className="btn-blue">Lorem</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default TemplateAbout