import React, { Component, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap';
import aboutImg from '../../assets/images/about-us.svg'
import "./About.css";

import LocalizedStrings from "react-localization";
import localizations from '../../localization';   

const About = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language')?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);


	useEffect(()=>{
		if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
		  translations.setLanguage(localStorage.getItem('language'));
		  setLang(localStorage.getItem('language'))
		}
	},[localStorage.getItem('language')])


	return (
		<div></div>
		
	)
}

export default About;