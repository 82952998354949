export default {
    "Choose from Vendors": "Hersteller",
    "Our Products Range": "Unser Produktangebot",
    "Our Best Products Range": "Unser Sortiment der besten Produkte",
    "Bestseller": "Bestseller",
    "View All Products" : "Mehr Artikel entdecken",
    "GET IN TOUCH" : "Kontakt",
    "Send us Enquiries": "Kontaktformular",
    "Skype" : "Skype Kontakt",
    "Location": "Anschrift",
    "Name": "Name",
    "Mobile":"Mobil",
    "Email": "Email",
    "State":"Zustand",
    "Street":"Strasse",
    "Phone Number" : "Telefonnummer",
    "Subject":"Ihr Anliegen",
    "Message" : "Text",
    "Home" : "Home",
    "Shop" : "Shop",
    "About Us" : "Über uns",
    "ABOUT US" : "ÜBER UNS",
    "Contact Us" : "Kontakt",
    "Login" : "Anmelden",
    "Individual":"Individuell",
    "Become a Reseller" : "Werden Sie Reseller",
    "100% German Licenses" : "100% Deutsche Lizenzen",
    "Partner Benefits" : "Partnervorteile",
    "Don't miss any promotions" : "Keine Aktionen Verpassen",
    "Our heart" : "Unser Herz schlägt für unsere Partner im Softwaregeschäft",
    "Our visions" : "Unsere Vision:",
    "Our vision" : "Sei der Beste oder nichts. Die Attivita das ist ein junges, buntes und erfahrenes Team im Softwaregeschäft. Unsere Vision leitet uns jeden Tag dazu an nur das Beste für unsere Kunden und Partner zu liefern. Besonders wichtig ist uns ein offener und jederzeit transparenter Umgang. Wir bieten ausschließlich authentifizierte Produkte in unserem Shop an - Wir lieben die Herausforderung das richtige Produkt und den besten Service für unser Partner und ihre Kunden zu finden und legen dabei höchsten Wert auf die Qualität und Herkunft unserer Produkte",
    "Our business" : "Unser Business wird angeleitet werden wir von Werten wie:",
    "Excellence" : "Exzellenz",
    "Excellences" : "Um jeden Tag nur das Beste für unsere Kunden und Partner zu liefern, lernen wir kontinuierlich von den Besten.",
    "Listen" : "Zuhören",
    "Listens" : "Wir hören aktiv zu was unsere Mitarbeiter, Kollegen und vor allem unsere Kunden und Partner uns mitteilen.",
    "Sparring" : "Sparring",
    "Sparrings" : "Unsere Kunden und Partner verstehen wir als Sparrings Partner, die in jedem Moment unseres gemeinsamen Weges optimal unterstützt, mit Ideen versorg und herausgefordert werden um ihr Geschäft erfolgreicher zu machen.",
    "Quality" : "Qualität",
    "Qualities" : "Bei der Qualität unserer Software gehen wir keine Kompromisse ein.Unser Steckenpferd: Norton 360. Die Software bietet umfassende Gerätesicherheit und ein VPN für ihre Privatsphäre im Netz. Bereits viele Millionen Menschen weltweit nutzen die Software schon, um ihre Internetsecurity erheblich zu verbessern. Die zwei wichtigsten Features sind das Dark Web Monitoring sowie das Blockieren von Online-Bedrohungen,durchschnittlich über neun Millionen am Tag.In unserem 24/7 Shop können unsere registrierten Partner jederzeit Produkte bestellen,welche in wenigen Augenblicken per Mail geliefert werden. Wir bauen unser Sortiment stetig aus und sind offen für jegliche Anfragen und Anregungen - was wir jetzt nicht haben, werden wir gleich besorgen.Wir lieben Software!",
    "WE CYBER-GUARD YOUR DATA, DEVICES & E-COMMUNICATIONS" : "WIR SCHÜTZEN IHRE DATEN, GERÄTE UND E-KOMMUNIKATION CYBERSCHUTZ",
    "In our 24/7 shop, our registered partners can order products at any time, which will be delivered by email in just a few moments. We are constantly expanding our range and are open to any inquiries and suggestions - if we don't have it now, we'll get it right away." : "In unserem 24/7 Shop können unsere registrierten Partner jederzeit Produkte bestellen, welche in wenigen Augenblicken per Mail geliefert werden. Wir bauen unser Sortiment stetig aus und sind offen für jegliche Anfragen und Anregungen - was wir jetzt nicht haben, werden wir gleich besorgen.",
    "We love software!" : "Wir lieben Software!",
    "How to become a reseller?" : "Wie wird man Reseller?",
    "How to buy and use the vouchers?" : "Wie können die Voucher kaufen und verwenden?",
    "For which products can vouchers be redeemed?" : "Für welche Produkte können Voucher eingelöst werden?",
    "Can discount codes be used?" : "Können Rabattcodes verwendet werden?",
    "How can an order be cancelled?" : "Wie kann eine Bestellung storniert werden?",
    "The right program suit for your customers at the best price" : "Die richtige Programmsuit für Ihre Kunden zum besten Preis",
    "Norton 360 - All-in-One Protection" : "Norton 360 - All-in-One Schutz",
    "Become a reseller now" : "Jetzt Reseller werden",
    "Nothing in the cart. Click":"Nichts im Warenkorb. Klicken",
    "here":"hier",
    "to add product to cart":"um ein Produkt in den Warenkorb zu legen",
    "Have a coupon":"Haben Sie einen Gutschein",
    "Enter Coupon Code":"Gutschein-Code eingeben",
    "Apply Coupon":"Gutschein anwenden",
    "Cart":"Wagen",
    "Coupon is invalid" : "Gutschein ist ungültig",
    "Sub Total" : "Zwischensumme",
    "Discount":"Rabatt",
    "VAT":"MwSt",
    "Total":"Gesamt",
    "Checkout":"Auschecken",
    "Inclusive of":"Inklusive",
    "Choose Variant":"Wählen Sie Variante",
    "Select Product Type":"Wählen Sie den Produkttyp aus",
    "Add To Cart":"In den Warenkorb legen",
    "Please select variation" : "Bitte Variante auswählen",
    "Complete Order":"Bestellung abschließen",
    "Terms & conditions and cancellation policy":"Allgemeine Geschäftsbedingungen und Widerrufsbelehrung",
    "Please note the cancellation policy.":"Bitte beachten Sie die Widerrufsbelehrung.",
    "I have read the terms and conditions and agree to them.":"Ich habe die AGB gelesen und stimme ihnen zu.",
    "I have read the":"Ich habe die",
    "terms and conditions":"Geschäftsbedingungen ",
    "and agree to them.":"gelesen und stimme diesen hiermit zu.",
    "Personal Details":"Persönliche Daten",
    "Billing Address":"Rechnungsadresse",
    "Full Name":"vollständiger Name",
    "Select address":"Adresse auswählen",
    "Select Country":"Land auswählen",
    "Country":"Land",
    "Address":"Die Anschrift",
    "Phone No":"Telefon-Nr",
    "Add New Address":"Neue Adresse hinzufügen",
    "Product":"Produkt",
    "Product Name":"Produktname",
    "Quantity":"Menge",
    "Excl.VAT":"ausschließlich MwSt",
    "Total Price":"Gesamtpreis",
    "Place Order":"Bestellung aufgeben",
    "You are not logged in to the system. Kindly":"Sie sind nicht am System angemeldet. Freundlich",
    "to continue":"weitermachen",
    "login":"anmeldung",
    "City":"Stadt",
    "Zip Code":"Postleitzahl",
    "Select from existing addresses":"Wählen Sie aus bestehenden Adressen aus",
    "Agree to terms and conditions":"Stimmen Sie den Geschäftsbedingungen zu",
    "Email is required":"E-Mail ist erforderlich",
    "Phone is required":"Telefon ist erforderlich",
    "Address is required":"Adresse ist erforderlich",
    "Country is required":"Land ist erforderlich",
    "City is required":"Stadt ist erforderlich",
    "Fullname is required":"Vollständiger Name ist erforderlich",
    "Select the address": "Wählen Sie die Adresse aus",
    "Thank you For Shopping With Attivita":"Vielen Dank für Ihren Einkauf bei Attivita",
    "Your order number:":"Ihre Auftragsnummer:",
    "Order confirmation email has been sent.":"Bestellbestätigungs-E-Mail wurde gesendet.",
    "Information":"Information",
    "Tax":"MwSt",
    "Dashboard":"Armaturenbrett",
    "Quotations":"Zitate",
    "Invoices":"Rechnungen",
    "Profile":"Profil",
    "Email Verified":"Email überprüft",
    "Account":"Konto",
    "Logout":"Ausloggen",
    "Email Not Verified":"E-Mail nicht verifiziert",
    "Welcome Back":"Willkommen zurück",
    "Login to your account":"ins Konto einloggen",
    "Password":"Passwort",
    "Remember Me":"Erinnere dich an mich",
    "Forget Password?":"Passwort vergessen?",
    "Sign In":"Anmelden",
    "Register As New Customer":"Neu hier? Erstellen Sie ein Konto.",
    "Hello User":"Hallo Benutzer",
    "Register As New Reseller":"Jetzt anmelden und Reseller werden",
    "Company Name":"Name der Firma",
    "Re type Password":"Passwort wiederholen",
    "Register":"Registrieren",
    "Prove you are a human":"Beweisen Sie, dass Sie ein Mensch sind",
    "Already Have a Customer Account":"Sie haben bereits ein Konto? Hier anmelden.",
    "Password is required" :"Passwort wird benötigt",
    "Name is required":"Name ist erforderlich",
    "Password must be 8 characters with atleast one character, capital and small alphabet with a number":"Das Passwort muss aus 8 Zeichen mit mindestens einem Zeichen, Groß- und Kleinbuchstaben mit einer Zahl bestehen",
    "Password does not match":"Passwort stimmt nicht überein",
    "Quotation":"Zitat",
    "Order Date":"Bestelldatum",
    "Order Detail":"Bestelldetails",
    "Sale order pricing":"Preise für Verkaufsaufträge",
    "Sales Orders":"Verkaufsaufträge",
    "Invoicing and Shipping Address":"Rechnungs- und Lieferadresse",
    "Invoice":"Rechnung",
    "Pricing":"Preisgestaltung",
    "Products":"Produkte",
    "Unit Price":"Einzelpreis",
    "Taxes":"Steuern",
    "Amount":"Höhe",
    "No order found":"Keine Bestellung gefunden",
    "Sales Order":"Verkaufsauftrag",
    "Invoice Date":"Rechnungsdatum",
    "Invoice Detail":"Rechnungsdetail",
    "Download Invoice":"Download Rechnung",
    "Order":"Befehl",
    "Description":"Beschreibung",
    "Payment Status":"Zahlungsstatus",
    "Un Paid":"Unbezahlt",
    "Partially Paid":"Teilweise bezahlt",
    "Paid":"Bezahlt",
    "Total Paid":"Ganz bezahlt",
    "Remaining Amount":"Restbetrag",
    "Refunded Amount":"Erstattete",
    "Inform your email account, we will send you a link to reset and create your new password":"Informieren Sie Ihr E-Mail-Konto, wir senden Ihnen einen Link zum Zurücksetzen und Erstellen Ihres neuen Passworts",
    "Enter Your Email":"Geben sie ihre E-Mail Adresse ein",
    "Submit":"einreichen",
    "Reset Password":"Passwort Zurücksetzen",
    "Order placed successfully" : "Bestellung erfolgreich aufgegeben",
    "Passwords do not match":"Passwörter stimmen nicht überein",
    "Do you like cookies?":"Magst du Kekse?",
    "We use cookies to ensure you get the best experience on our website.":"Wir verwenden Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website machen.",
    "Learn more":"Erfahren Sie mehr",
    "I agree":"Ich stimme zu",
    "Discount Percentage":"Rabattprozentsatz",
    "Discount":"Rabatt",
    "Email successfully verified!":"E-Mail erfolgreich bestätigt!",
    "Continue":"Weitermachen",
    "Your account is not active yet. Kindly contact admin to continue.":"Ihr Konto ist noch nicht aktiv. Bitte wenden Sie sich an den Administrator, um fortzufahren.",
    "Profile Updated":"Profil aktualisiert",
    "Company Name is required":"Firmenname ist erforderlich",
    "Street is required":"Straße ist erforderlich",
    "(Required)":"(Erforderlich)",
    "(Optional)":"(wahlweise)",
    "Your account is not active. Kindly contact admin to verify your account.":"Ihr Konto wird zur Zeit geprüft. Sobald es aktiviert ist, erhalten Sie eine Bestätigung.",
    "Your Query has been submitted":"Ihre Anfrage wurde übermittelt",
    "Message is required":"Nachricht ist erforderlich",
    "Subject is required":"Betreff ist erforderlich",
    "Continue Shopping":"Mit dem Einkaufen fortfahren",
    "Low License Product":"Produkt mit niedriger Lizenz",
    "You can\'t place order. Low license products are added in your cart":"Sie können keine Bestellung aufgeben. Produkte mit niedriger Lizenz werden Ihrem Warenkorb hinzugefügt",
    "Low license products can\'t be added in your cart":"Produkte mit niedriger Lizenz können nicht in Ihren Warenkorb gelegt werden",
    "Coupon is required":"Gutschein ist erforderlich",
    "ALL-IN-ONE":"ALLES IN EINEM",
    "TRUE CYBER PROTECTION":"ECHTER CYBER-SCHUTZ",
    "Price: Asc":"Preis: Aufsteigend",
    "Price: Desc":"Preis: Absteigend",
    "Name: Asc":"Name: Aufsteigend",
    "Name: Desc":"Name: Absteigend",
    "Sort by":"Sortiere nach",
    "Search" : "Suchen",
    "License for this variant will be available soon":"Die Lizenz für diese Variante wird in Kürze verfügbar sein",
    'Licenses Available':"Lizenzen verfügbar",
    'Company Type':'Wiederverkäufertyp',
    'Company':'Unternehmen',
    'Company URL':'Firmen-URL',
    'Select City':'Select City',
    'here':'Hier',
    'go to login to see all product details':'gehts zur Anmeldung um alle Produktdetails zu sehen.',
    'Information is missing. Please enter your address in your profile.':'Informationen fehlen. Bitte geben Sie Ihre Adresse in Ihrem Profil ein.',
    'Company name is required':'Firmenname ist erforderlich',
    "Company url is required":"Die URL des Unternehmens ist falsch",
    "Partially Refunded": "Partially Erstattete",
    "Refunded": "Erstattete",
    "Refunded Orders": "Erstattete Aufträge",
    "Refunded Order": "Erstattete Aufträge",
    "Refund Date":"Erstattungsdatum",
    "New Password":"Neues Passwort",
    "Confirm Password":"Bestätigen Sie Ihr Passwort",
    "Company Registration Extract":"Auszug aus der Unternehmensregistrierung",
    "Company VAT":"Unternehmen Mehrwertsteuer",
    "Recommended Image Size": "Empfohlene Bildgröße",
    "Update":"Aktualisierung",
    "Company vat is required":"Unternehmensumsatzsteuer ist erforderlich",
    "View Extract":'Ansicht Auszug',
    "Zip Code is required":"Postleitzahl ist erforderlich",
    "Company VAT is required":"Umsatzsteuer für Unternehmen ist erforderlich",
    "No quotation found":"Kein Angebot gefunden"

};