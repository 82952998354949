import Dashboard from '../components/dashboard/Dashboard';
import QuotationListing from '../components/dashboard/QuotationListing';
import SalesOrdersListing from '../components/dashboard/SalesOrdersListing';
import RefundedOrdersListing from '../components/dashboard/RefundedOrdersListing';
import InvoicesListing from '../components/dashboard/InvoicesListing';
import QuotationDetail from '../components/quotationdetail/QuotationDetail';
import InvoiceDetail from '../components/invoicedetail/InvoiceDetail';
import Thankyou from '../components/thankyou/Thankyou';
import UserProfile from '../components/userprofile/UserProfile';



var routes = [
    {
        path: "/dashboard/:de",
        exact: true,
        component: Dashboard
    },
    {
        path: "/quotationlisting/:de",
        exact: true,
        component: QuotationListing
    },
    
    {
        path: "/salesorderlisting/:de",
        exact: true,
        component: SalesOrdersListing
    },
    {
        path: "/refundedorderlisting/:de",
        exact: true,
        component: RefundedOrdersListing
    },
    {
        path: "/invoicelisting/:de",
        exact: true,
        component: InvoicesListing
    },
    {
        path: "/quotationdetail/:id/:de",
        exact: true,
        component: QuotationDetail
    },
    {
        path: "/invoicedetail/:id/:de",
        exact: true,
        component: InvoiceDetail
    },
    {
        path: "/userProfile/:de",
        exact: true,
        component: UserProfile
    },
    {
        path: "/thankyou/:id/:de",
        exact: true,
        component: Thankyou
    },
]

export default routes;