import React, { Component, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap';
import SpecialityImg from '../../assets/images/about-us-section.jpg'
import SpecialityFastImg from '../../assets/images/about-speciality-fast.svg'
import SpecialityCcImg from '../../assets/images/about-speciality-cc.svg'
import SpecialityDuplicateImg from '../../assets/images/about-speciality-duplicate.svg'
import SpecialityCameraImg from '../../assets/images/about-speciality-camera.svg'


import "./AboutSpeciality.css";

import LocalizedStrings from "react-localization";
import localizations from '../../localization';


const AboutSpeciality = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(() => {
		if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
			translations.setLanguage(localStorage.getItem('language'));
			setLang(localStorage.getItem('language'))
		}
	}, [localStorage.getItem('language')])

	return (
		<section className="about-us-speciality py-5">
			<Container fluid>
				{/* <Row className="align-items-center">
					<Col lg="6" md="12" xs="12"> */}
						<div className="about-us-speciality-img text-center d-inline-block">
							<img src={SpecialityImg} className="img-fluid " alt="about-us" title="" />
						</div>
					{/* </Col>
					<Col lg="6" md="12" xs="12"> */}
						<div className="about-us-speciality-main">
							<ul className="list-unstyled mt-3 mb-3">
								<li className="d-flex">
									<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
										<div className="speciality-icon-bg-img">
											<img src={SpecialityFastImg} alt="icon" className="img-fluid" />
										</div>
									</div>
									<div className="about-us-speciality-text">
										<h3>{translations["Excellence"]}</h3>
										<p>{translations["Excellences"]}.</p>
									</div>
								</li>
								<li className="d-flex">
									<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
										<div className="speciality-icon-bg-img">
											<img src={SpecialityCcImg} alt="icon" className="img-fluid" />
										</div>
									</div>
									<div className="about-us-speciality-text">
										<h3>{translations["Listen"]}</h3>
										<p>{translations["Listens"]}</p>
									</div>
								</li>

								<li className="d-flex">
									<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
										<div className="speciality-icon-bg-img">
											<img src={SpecialityDuplicateImg} alt="icon" className="img-fluid" />
										</div>
									</div>
									<div className="about-us-speciality-text">
										<h3>{translations["Sparring"]}</h3>
										<p>{translations["Sparrings"]}</p>
									</div>
								</li>
								<li className="d-flex">
									<div className="speciality-icon-bg d-flex justify-content-center align-items-center">
										<div className="speciality-icon-bg-img">
											<img src={SpecialityCameraImg} alt="icon" className="img-fluid" />
										</div>
									</div>
									<div className="about-us-speciality-text">
										<h3>{translations["Quality"]}</h3>
										<p>{translations["Qualities"]}</p>
									</div>
								</li>
							</ul>
						</div>
					{/* </Col>
				</Row> */}
			</Container>
		</section>

	)
}


export default AboutSpeciality;