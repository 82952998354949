import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../header/Header';
import AuthHeader from "../authheader/AuthHeader";
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../footer/Footer';
import { ENV } from "../../config/config";
import { getPage, beforeCMS } from './CMS.action';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ContactUs from './contactus/index'
import ContactUSTwo from './contactus2/index'
import ReactDOM from 'react-dom';
const CMS = (props) => {
    const myRef = useRef(null)
    let { customername } = ENV.getUserKeys();
    let [pageContent, setPageContent] = useState(null)
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'de')
    const [loader, setLoader] = useState(true)
    const [formKeyWord, setFormKeyWord] = useState(null)
    let { slug } = useParams();
    const contentRef = useRef(null);
    let data = {
        contactus: 'contactus',
    }
    useEffect(() => {
        props.getPage(window.location.pathname.split('/')[2])
    }, []);
    useEffect(() => {

        props.getPage(window.location.pathname.split('/')[2])
    }, [slug]);

    useEffect(() => {
        if (myRef.current)
            myRef.current.scrollIntoView()

        if (pageContent) {


            if (contentRef.current) {
                contentRef.current.innerHTML = pageContent;
            }
            if (formKeyWord==='contactus') {
                ReactDOM.render(<ContactUs />, document.getElementById('contactUsPlaceholder'));
            }else if(formKeyWord && formKeyWord!='contactus'){
                ReactDOM.render(<ContactUSTwo />, document.getElementById('contactUsPlaceholder'));
            }

           
        }

    }, [pageContent]);



    useEffect(() => {
        if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
            setLanguage(localStorage.getItem('language'))
        }
    }, [localStorage.getItem('language')])

    useEffect(() => {
        if (props.cms.getCms) {
            if (language == 'de') {

                setPageContent(props.cms.content.germanDescription)
            } else {
                let content = props.cms.content.description;
                if (props.cms.content?.formKeyWord) {
                    setFormKeyWord(props.cms.content.formKeyWord)
                    let thisKey = props.cms.content.formKeyWord;
                    let keyInContent = "${{" + thisKey + "}}";
                    let regEx = new RegExp(keyInContent.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi')
                    if (thisKey === props.cms.content.formKeyWord) {
                        content = content.replace(regEx, `<div id="contactUsPlaceholder"></div>`)

                    }
                }
                setPageContent(content)

            }
            props.beforeCMS()
            setLoader(false)
        }
    }, [props.cms.getCms]);
    // Render the modified content
    return (
        <div>
            {customername ? <AuthHeader /> : <Header />}

            {!loader ?
            formKeyWord ?
                <div id="main-header" ref={myRef}>
                    {/* <section className="terms-of-use-content cms-data-protection"> */}
                    {/* <Container> */}
                    <Row>
                        {pageContent &&
                            <Col sm={12} ref={contentRef}>

                            </Col>
                        }
                    </Row>
                    {/* </Container> */}
                    {/* </section> */}
                    <Footer />
                </div>
                :
                <div id="main-header" ref={myRef}>
                    <section className="terms-of-use-content cms-data-protection">
                    <Container fluid className='custom-container-cms'>
                    <Row>
                        {pageContent &&
                            <Col sm={12} ref={contentRef}>

                            </Col>
                        }
                    </Row>
                    </Container>
                    </section>
                    <Footer />
                </div>
                :
                <SkeletonTheme color="#202020" highlightColor="#7E9DF3">
                    <Container>
                        <Row>
                            <Col className="mt-3">
                                <Skeleton count={10} />
                                <br />
                                <Skeleton count={10} />
                                <br />
                                <Skeleton count={10} />
                            </Col>

                        </Row>
                    </Container>
                </SkeletonTheme>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    cms: state.cms
})
export default connect(mapStateToProps, { getPage, beforeCMS })(CMS)

