import React, { Component } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col} from 'react-bootstrap';
import HomeSlider from '../homeslider/HomeSlider';


const Banner = (props) => {
	return (
		<HomeSlider />
        )
}

export default Banner;