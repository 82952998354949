import React, { Component, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ShopBannerImg from '../../assets/images/shop-banner.png'
import "./ShopBanner.css";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';



const ShopBanner = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
let translations = new LocalizedStrings(localizations);
translations.setLanguage(lang);

useEffect(()=>{
	if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
	  translations.setLanguage(localStorage.getItem('language'));
	  setLang(localStorage.getItem('language'))
	}
},[localStorage.getItem('language')])


	return (
			<section className="shop-banner custom-container">
				<Container fluid>
					<Row className="align-items-center">
						<Col lg="6" md="6" xs="12">
							<div className="banner-text">
								<h3>{translations['ALL-IN-ONE']}</h3>
								<h1>{translations['TRUE CYBER PROTECTION']}</h1>
							</div>
						</Col>
						<Col lg="6" md="6" xs="12">
							<div className="banner-img">
								<img src={ShopBannerImg} className="img-fluid" alt="laptop" title="" />
							</div>
						</Col>
					</Row>
				</Container>
			</section>

		)
}


export default ShopBanner;