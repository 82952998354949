import React, { useState, useMemo, useEffect, useRef, Fragment } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col, Accordion, Card, Button, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import LoginImg from '../../assets/images/side-logo.svg'
import frontLoader from '../../assets/images/front-loader.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import countryList from 'react-select-country-list';
import { connect } from "react-redux";
import { registerAction, beforeLogin, loginAction } from "../login/login.action";
import { getCountries } from "../checkout/checkout.action";
import "./Register.css";
import swal from 'sweetalert'
import ReCAPTCHA from "react-google-recaptcha";
import { ENV } from "../../config/config";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';

const Register = (props) => {

    const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
    let translations = new LocalizedStrings(localizations);
    translations.setLanguage(lang);

    useEffect(() => {
        if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
            translations.setLanguage(localStorage.getItem('language'));
            setLang(localStorage.getItem('language'))
        }
    }, [localStorage.getItem('language')])

    const history = useHistory()
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const companyRef = useRef(null);
    const mobileRef = useRef(null);
    const stateRef = useRef(null);
    const streetRef = useRef(null);
    const zipCodeRef = useRef(null);
    const cityNameRef = useRef(null);
    const [value, setValue] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmPassword] = useState(false);
    const [countriesList, setCountriesList] = useState();
    const [citiesList, setCitiesList] = useState();
    const [captcha, setCaptcha] = useState('')
    const [open, setOpen] = useState(false)

    const [userData, setUserData] = useState({
        customername: "",
        email: "",
        password: "",
        confirmPassword: "",
        countryName: "",
        cityName: "",
        street: "",
        state: "",
        zipCode: "",
        companyName: "",
        mobile: "",
        state: "",
        street: "",
        zipCode: "",
        companyType: true,
        companyURL: "",
        companyVat:""
    });
    const [msg, setMsg] = useState({
        customername: "",
        email: "",
        password: "",
        confirmPassword: "",
        countryName: "",
        street: "",
        cityName: "",
        companyName: "",
        companyURL: "",
        companyVat:"",
        zipCode:"",
    });

    useEffect(() => {
        props.getCountries()

    }, [])

    useEffect(() => {
        if (props.checkout.getCountries) {
            let countryArray = props.checkout.countries
            let countryList = Object.keys(countryArray).map((item) => (
                {
                    label: countryArray[item].name,
                    value: countryArray[item].name,
                }
            ))
            setCountriesList(countryList)
        }
    }, [props.checkout.getCountries])

    useEffect(() => {
        if (props.errors?.message) {
            swal({
                text: props.errors.message,
                icon: "error",
                button: "OK",
            });
        }
    }, [props.errors]);

    useEffect(() => {
        if (props.login.registerActionAuth) {
            swal({
                text: props.login.registerActionMessage,
                icon: frontLoader,
                timer: 4000,
                button: "OK",
            });



            setTimeout(function () {
                login({
                    email: userData.email,
                    password: userData.password
                })
            }, 4000);

            setUserData({
                customername: "",
                email: "",
                password: "",
                confirmPassword: "",
                countryName: "",
                cityName: "",
                street: "",
                state: "",
                zipCode: "",
                mobile: "",
                state: "",
                zipCode: "",
                companyType: true,
                companyURL: "",
                companyVat:""
            })
            props.beforeLogin()
        }

    }, [props.login.registerActionAuth])

    useEffect(() => {
        if (props.login.loginActionAuth) {
            props.beforeLogin()
            history.push(`/${lang}`);
        }
    }, [props.login.loginActionAuth])

    const checkCompanyUrl = (url) => {
        if (url && url?.length > 0) {
            const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
            if (pattern.test(url)) {
                return true;
            } else {

                return false;
            }
        } else {

            return true
        }
    }
    const register = (e) => {
        e.preventDefault();
        if (captcha && userData.customername && userData.email  && userData.zipCode && userData.password && userData.confirmPassword && userData.countryName && (userData.companyType ? userData.companyName : true)) {
            if (userData.companyVat && userData.companyName && !checkCompanyUrl(userData.companyURL)) {

                setMsg({
                    customername: !userData.customername ? translations['Name is required'] : '',
                    email: !userData.email ? translations['Email is required'] : '',
                    password: !userData.password ? translations['Password is required'] : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(userData.password) ? '' : translations['Password must be 8 characters with atleast one character, capital and small alphabet with a number'],
                    confirmPassword: userData.confirmPassword != userData.password ? translations['Password does not match'] : '',
                    countryName: !userData.countryName ? translations['Country is required'] : '',
                    cityName: !userData.cityName ? translations['City is required'] : '',
                    captcha: !captcha ? translations['Prove you are a human'] : '',
                    street: !userData.street ? translations['Street is required'] : '',
                    zipCode: !userData.zipCode ? translations['Zip Code is required'] : '',
                    companyName: userData.companyType ? !userData.companyName ? translations['Company name is required'] : '' : '',
                    companyVat: userData.companyType ? !userData.companyVat ? translations['Company vat is required'] : '' : '',
                    companyURL: userData.companyType ? !checkCompanyUrl(userData.companyURL) ? translations['Company url is required'] : '' : '',

                });
                return false;
            }
            if (userData.password !== userData.confirmPassword) {
                swal({
                    text: translations['Passwords do not match'],
                    icon: "error",
                    button: "OK!",
                });
                return;
            }
            setMsg({
                customername: "",
                email: "",
                password: "",
                confirmPassword: "",
                countryName: "",
                cityName: "",
                street: "",
                zipCode:""
            });
            let payload = {
                customername: userData.customername,
                email: userData.email,
                password: userData.password,
                countryName: userData.countryName,
                companyName: userData.companyName,
                emailLanguage: lang,
                state: userData.state,
                zipCode: userData.zipCode,
                mobile: userData.mobile,
                street: userData.street,
                cityName: userData.cityName,
                companyType: userData.companyType,
                companyVAT: userData.companyVat,
                companyURL: userData.companyURL
            };
            props.registerAction(payload);
            props.beforeLogin();
        } else {
            setMsg({
                customername: !userData.customername ? translations['Name is required'] : '',
                email: !userData.email ? translations['Email is required'] : '',
                password: !userData.password ? translations['Password is required'] : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(userData.password) ? '' : translations['Password must be 8 characters with atleast one character, capital and small alphabet with a number'],
                confirmPassword: userData.confirmPassword != userData.password ? translations['Password does not match'] : '',
                countryName: !userData.countryName ? translations['Country is required'] : '',
                cityName: !userData.cityName ? translations['City is required'] : '',
                captcha: !captcha ? translations['Prove you are a human'] : '',
                zipCode: !userData.zipCode ? translations['Zip Code is required'] : '',
                street: !userData.street ? translations['Street is required'] : '',
                companyName: userData.companyType ? !userData.companyName ? translations['Company name is required'] : '' : '',
                companyVat: userData.companyType ? !userData.companyVat ? translations['Company vat is required'] : '' : '',
                companyURL: userData.companyType ? !checkCompanyUrl(userData.companyURL) ? translations['Company url is required'] : '' : '',

            });
        }
    }
    const removeSpaces = (e, ref) => {
        ref.current.value = e.target.value.replace(/^\s+/, "")

    }
    const login = (payload) => {
        props.loginAction(payload)
    }
    const showPasswordMethod = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword)
    }
    const showConfirmPasswordMethod = (e) => {
        e.preventDefault();
        setConfirmPassword(!showConfirmPassword)
    }

    const handleCountryChange = (option) => {
        setUserData({
            ...userData,
            countryName: option.label
        })
        var cities = require('countries-cities').getCities(option.label);
        let citiesArray = cities.map((item, key) => (
            {
                label: item,
            }
        ))
        setCitiesList(citiesArray)
    }

    function onChange(value) {
        setCaptcha(value)
    }
    function onExpired(value) {
        setCaptcha('')
    }

    let { customername } = ENV.getUserKeys();

    if (customername) {
        history.push('/')
        return ''
    } else {
        const customStyles = {
            control: base => ({
                ...base,
            })
        };


        return (
            <section className="wrapper">
                <Container fluid>
                    <Form onSubmit={(e) => register(e)}>
                        <Row className="align-items-center">
                            <Col lg="4" md="4" xs="12" className="pl-0 pr-0">
                                <div className="form-side-display d-flex justify-content-center align-items-center">
                                    <Link to={`/${lang}`} className="d-inline-block">
                                        <img src={LoginImg} alt="logo" className="img-fluid" />
                                    </Link>
                                </div>
                            </Col>
                            <Col lg="8" md="8" xs="12" className="pl-0 pr-0 ">
                                <div className="login-main new-logged-des">
                                    <div className="login-main-child">
                                        <h6>{translations['Hello User']}</h6>
                                        <h2>{translations['Register As New Reseller']}</h2>

                                        <form className="login-form-main">
                                            <div className="mb-3 login-form-input">
                                                <input type="text" className="form-control" placeholder={translations['Name'] + ` ` + translations['(Required)']} id="exampleInputText1"
                                                    value={userData.customername}
                                                    onChange={(e) => setUserData({ ...userData, customername: e.target.value })}
                                                    onKeyUp={(e) => removeSpaces(e, nameRef)}
                                                    ref={nameRef}
                                                    autoFocus={true}

                                                />
                                                {msg.customername && <small className="error">{msg.customername}</small>}
                                            </div>
                                            <div className="mb-3 login-form-input">
                                                <input type="email" className="form-control" placeholder={translations['Email'] + ` ` + translations['(Required)']} id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    value={userData.email}
                                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                                    ref={emailRef}
                                                    onKeyUp={(e) => removeSpaces(e, emailRef)}
                                                />
                                                {msg.email && <small className="error">{msg.email}</small>}
                                            </div>
                                            <div className="mb-3 login-form-input">
                                                <input type="text" className="form-control" placeholder={translations['Mobile'] + ` ` + translations['(Optional)']}
                                                    value={userData.mobile}
                                                    onChange={(e) => setUserData({ ...userData, mobile: e.target.value })}
                                                    ref={mobileRef}
                                                    onKeyUp={(e) => removeSpaces(e, mobileRef)}
                                                />
                                            </div>
                                            <div className="mb-3 login-form-input">
                                                <input type="text" className="form-control" placeholder={translations['State'] + ` ` + translations['(Optional)']}
                                                    value={userData.state}
                                                    onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                                                    ref={stateRef}
                                                    onKeyUp={(e) => removeSpaces(e, stateRef)}
                                                />
                                            </div>
                                            <div className="mb-3 login-form-input">
                                                <input type="text" className="form-control" placeholder={translations['Street'] + ` ` + translations['(Required)']}
                                                    value={userData.street}
                                                    onChange={(e) => setUserData({ ...userData, street: e.target.value })}
                                                    ref={streetRef}
                                                    onKeyUp={(e) => removeSpaces(e, streetRef)}
                                                />
                                                {msg.street && <small className="error">{msg.street}</small>}
                                            </div>
                                            <div className="mb-3 login-form-input">
                                                <input type="text" className="form-control" placeholder={translations['Zip Code'] + ` ` + translations['(Required)']}
                                                    value={userData.zipCode}
                                                    onChange={(e) => setUserData({ ...userData, zipCode: e.target.value })}
                                                    ref={zipCodeRef}
                                                    onKeyUp={(e) => removeSpaces(e, zipCodeRef)}
                                                />
                                                {msg.zipCode && <small className="error">{msg.zipCode}</small>}
                                            </div>
                                            <div className="mb-3 login-form-input">
                                                <Select
                                                    options={countriesList}
                                                    placeholder={translations['Select Country']}
                                                    onChange={(option) => handleCountryChange(option)}
                                                    value={countriesList?.filter(option => option.value === userData.countryName)}
                                                    styles={customStyles}
                                                />
                                                {msg.countryName && <small className="error">{msg.countryName}</small>}
                                            </div>
                                            <div className="mb-3 login-form-input">

                                                {/* <Select
                                                    options={citiesList}
                                                    placeholder="Select City"
                                                    onChange={(option) => {
                                                        setUserData({ ...userData, cityName: option.label })

                                                    }}
                                                    value={citiesList?.filter(option => option.label === userData.cityName)}
                                                /> */}
                                                <input type="text" className="form-control" placeholder={translations['City'] + ` ` + translations['(Required)']}
                                                    value={userData.cityName}
                                                    onChange={(e) => setUserData({ ...userData, cityName: e.target.value })}
                                                    ref={cityNameRef}
                                                    onKeyUp={(e) => removeSpaces(e, cityNameRef)}
                                                />
                                                {msg.cityName && <small className="error">{msg.cityName}</small>}
                                            </div>
                                            <div className="mb-3 login-form-input">
                                                {/* <label for="exampleInputPassword1" className="form-label">{translations['Company Type']}</label> */}
                                                <div className="voucher-w-50">
                                                    {/* <label className="right-label-radio mb-2 mr-2">
                                                        <div className='d-flex align-items-center'>
                                                            <input name="companyType" type="radio" checked={userData.companyType} value={userData.companyType} onChange={(e) => { setUserData({ ...userData, companyType: true }) }} />
                                                            <span className="checkmark"></span>
                                                            <span className='ml-1' onChange={(e) => {
                                                                setUserData({ ...userData, companyType: true })


                                                            }} ><i />{translations['Company']}</span>
                                                        </div>
                                                    </label> */}
                                                    {/* <label className="right-label-radio mr-3 mb-2">
                                                        <div className='d-flex align-items-center'>
                                                            <input name="companyType" type="radio" checked={!userData.companyType} value={!userData.companyType} onChange={(e) => { setUserData({ ...userData, companyType: false }) }} />
                                                            <span className="checkmark"></span>
                                                            <span className='ml-1' onChange={(e) => {
                                                                setUserData({ ...userData, companyType: false })


                                                            }} ><i />{translations['Individual']}</span>
                                                        </div>
                                                    </label> */}
                                                </div>
                                            </div>
                                            {userData.companyType &&
                                                <>
                                                    <div className="mb-3 login-form-input">
                                                        <input type="text" className="form-control" placeholder={translations['Company VAT'] + ` ` + translations['(Required)']}
                                                            value={userData.companyVat}
                                                            onChange={(e) => setUserData({ ...userData, companyVat: e.target.value })}

                                                        />
                                                        {msg.companyVat && <small className="error">{msg.companyVat}</small>}
                                                    </div>
                                                    <div className="mb-3 login-form-input">
                                                        <input type="text" className="form-control" placeholder={translations['Company Name'] + ` ` + translations['(Required)']}
                                                            value={userData.companyName}
                                                            onChange={(e) => setUserData({ ...userData, companyName: e.target.value })}
                                                            ref={companyRef}
                                                            onKeyUp={(e) => removeSpaces(e, companyRef)}
                                                        />
                                                        {msg.companyName && <small className="error">{msg.companyName}</small>}
                                                    </div>
                                                    <div className="mb-3 login-form-input">
                                                        <input type="text" className="form-control" placeholder={' https://company.org/' + ` ` + translations['(Optional)']}
                                                            value={userData.companyURL}
                                                            onChange={(e) => {
                                                                setUserData({ ...userData, companyURL: e.target.value })
                                                            }
                                                            } />
                                                        {msg.companyURL && <small className="error">{msg.companyURL}</small>}

                                                    </div>
                                                </>

                                            }



                                            <div className="mb-3 login-form-input form-password-eye-box">
                                                <input type={showPassword ? "text" : "password"} className="form-control" placeholder={translations['Password'] + ` ` + translations['(Required)']} id="exampleInputPassword1"
                                                    value={userData.password}
                                                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                                />
                                                <a onClick={(e) => showPasswordMethod(e)} className="form-password-eye">
                                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                                </a>
                                                {msg.password && <small className="error">{msg.password}</small>}
                                            </div>
                                            <div className="mb-3 login-form-input form-password-eye-box">
                                                <input type={showConfirmPassword ? "text" : "password"} className="form-control" placeholder={translations['Re type Password'] + ` ` + translations['(Required)']} id="exampleInputConfirmPassword1"
                                                    value={userData.confirmPassword}
                                                    onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                                                />
                                                <a onClick={(e) => showConfirmPasswordMethod(e)} className="form-password-eye">
                                                    <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                                                </a>
                                                {msg.confirmPassword && <small className="error">{msg.confirmPassword}</small>}
                                            </div>
                                            <div className="form-floating mb-3 login-form-input">
                                                {/* {/* <Accordion className="address-option"  >
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="text-capitalize" ref={inputRef} onClick={() => setOpen(!open)}>
                                                        Select address options
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0" >
                                                    <Card.Body>
                                                        <div className="mb-2 login-form-input">
                                                            <Select
                                                                options={countriesList}
                                                                placeholder="Select Country"
                                                                onChange={(option) => handleCountryChange(option)}
                                                                value={countriesList?.filter(option => option.value === userData.countryName)}
                                                            />
                                                            {msg.countryName && <small className="error">{msg.countryName}</small>}
                                                        </div>
                                                        <div className="mb-2 login-form-input">
                                                            <Select
                                                                onChange={(option) => setUserData({ ...userData, cityName: option.label })}
                                                                value={citiesList?.filter(option => option.label === userData.cityName)}
                                                            />
                                                        </div>
                                                        <div className="mb-2 login-form-input">
                                                            <input type="text" className="form-control" placeholder="Street" id="exampleInputStreet1"
                                                                onChange={(e) => setUserData({ ...userData, street: e.target.value })}
                                                                value={userData.street}
                                                            />
                                                            {msg.street && <small className="error">{msg.street}</small>}
                                                        </div>
                                                        <div className="mb-2 login-form-input">
                                                            <input type="text" className="form-control" placeholder="State" id="exampleInputState1"
                                                                onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                                                                value={userData.state}
                                                            />
                                                        </div>
                                                        <div className="mb-2 login-form-input">
                                                            <input type="text" className="form-control" placeholder="Zipcode" id="exampleInputZipcode1"
                                                                onChange={(e) => setUserData({ ...userData, zipCode: e.target.value })}
                                                                value={userData.zipCode}
                                                            />
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>      options={citiesList} */}
                                                {/* placeholder="Select City" */}

                                            </div>
                                            <div className="row align-items-center recaptcha-btn-wrap ">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="g-recaptcha">
                                                        <ReCAPTCHA
                                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                            onChange={onChange}
                                                            onExpired={onExpired}
                                                        />
                                                        {msg.captcha && <small className="error">{msg.captcha}</small>}

                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="contact-us-main-btn">
                                                        <button onClick={(e) => register(e)} type="submit" className="main-btn">{translations['Register']}</button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <Link to={`/login/${lang}`} id="register-new-user">{translations['Already Have a Customer Account']}</Link>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container >
            </section >
        )
    }
}


const mapStateToProps = state => ({
    errors: state.errors,
    login: state.login,
    checkout: state.checkout
})
export default connect(mapStateToProps, { beforeLogin, registerAction, getCountries, loginAction })(Register);