import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Header from '../header/Header';
import AuthHeader from "../authheader/AuthHeader";
import Footer from '../footer/Footer';
import SimpleSlider from '../simpleslider/SimpleSlider';
import { connect } from 'react-redux';
import { getProduct, beforeProducts } from "../product/product.action";
import Select from 'react-select';
import productOffcImg from '../../assets/images/products-office.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { ENV } from "../../config/config";
import { variationCurrencyFormat, currencyFormat, averageFormat,checkUserPackagePrice } from "../../utils/functions"
import { getVATforCountry } from "../siteSettings/siteSettings.action";
import { beforeCart, addToUserCart } from '../cart/cart.action'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import swal from 'sweetalert'

import FullPageLoader from '../FullPageLoader/FullPageLoader'
import "./ProductDetail.css";
import Cart from "../cart/Cart";
import { transitions } from "react-alert";

import LocalizedStrings from "react-localization";
import localizations from '../../localization';

const moment = require('moment')


const ProductDetail = (props) => {


	const history = useHistory();
	const myRef = useRef(null)
	let userData = ENV.getUserKeys();
	const [product, setProduct] = useState()
	const [vatPercentage, setVatPercentage] = useState(props.siteSetting.getCountry ? props.siteSetting.vat : props.siteSetting.getSettings ? props.siteSetting.settings.vatPercentage : 19)

	const [vatLabel, setVatLabel] = useState('VAT')
	const [variationError, setVariationError] = useState(false)
	const [loader, setLoader] = useState(false)
	const [cart, setaddToCart] = useState(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [])
	const [cartTotals, setCartTotals] = useState(JSON.parse(localStorage.getItem("cartTotal")) ? JSON.parse(localStorage.getItem("cartTotal")) : {
		subTotal: 0,
		discount: 0,
		tax: 0,
		total: 0,
	})

	const [licenses, setLicenses] = useState(0)

	const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'en')

	const [currency, setCurrency] = useState({
		symbol: "€",
		code: "EUR"
	})
	const [selectedVariation, setSelectedVariation] = useState({
		id: '',
		price: '',
		quantity: 1,
		variationName: '',
		totalLicenses: 0
	})
	const [variationOptions, setVariationOptions] = useState([])

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(() => {
		if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
			translations.setLanguage(localStorage.getItem('language'));
			setLang(localStorage.getItem('language'))
		}
	}, [localStorage.getItem('language')])



	useEffect(() => {

		if (!ENV.checkUserAuth()) {
			history.push(`/login/${localStorage.getItem('language')}`)
		}
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
		if (productWithId) {
			let productData = {
				id: productWithId.productId,
				price: productWithId.price,
				quantity: productWithId.quantity,
				variationName: productWithId.variationName
			}
			setSelectedVariation(productData)
		}
		myRef.current.scrollIntoView()
		props.getProduct(window.location.pathname.split('/')[2])
	}, []);

	useEffect(() => {
		if (props.product.getproduct) {
			switch (language) {
				case 'en':
					break;
				case 'de':
					props.product.product.shortDescription = props.product.product.shortDescriptionDE
					props.product.product.longDescription = props.product.product.longDescriptionDE
					break;

			}
			setProduct(props.product.product)
			let variationOptions_ = []
			if (lang == "de") {
				variationOptions_ = props.product.product.variations?.map(variation => ({
					label: variation.details.map((detail, index_) => {
						return (
							detail.attributeNameDE + ": " + detail.attributeValue
						)
					}).join(', '),
					value: variation._id,
					price: variation.salesPrice,
				}))

			} else {
				variationOptions_ = props.product.product.variations?.map(variation => ({
					label: variation.details.map((detail, index_) => {
						return (
							detail.attributeName + ": " + detail.attributeValue
						)
					}).join(', '),
					value: variation._id,
					price: variation.salesPrice,
				}))
			}
			setVariationOptions([...variationOptions_])
			setSelectedVariation({ ...selectedVariation, price: props.product.product.salesPrice, totalLicenses: props.product.product.licenses.length })
			props.beforeProducts()
		}
	}, [props.product.getproduct]);

	useEffect(() => {
		if (props.siteSetting.getSettings) {
			if (userData?.addresses && userData?.addresses?.length > 0) {
				props.getVATforCountry(userData?.addresses[0]?.country)
			}
			else {
				setVatPercentage(props.siteSetting.settings.vatPercentage)
				setVatLabel("VAT")
			}
		}
	}, [props.siteSetting.getSettings]);

	useEffect(() => {
		if (props.siteSetting.getCountry) {
			setVatPercentage(props.siteSetting.vat)
			setVatLabel(props.siteSetting.vat_label)
			updateCart()
		}
	}, [props.siteSetting.getCountry]);

	useEffect(() => {
		if (props.cart.cartAddedAuth) {
			props.beforeCart()

			setLoader(false)
		}

	}, [props.cart.cartAddedAuth]);

	useEffect(() => {
		if (props.cart.cartNotAddedAuth) {
			swal({
				text: translations['Your account is not active yet. Kindly contact admin to continue.'],
				icon: "warning",
				button: "OK!",
			});
			localStorage.removeItem('coupon')
			localStorage.removeItem('cart')
			localStorage.removeItem('cartTotal')
			handleClose()
			props.beforeCart()
			setLoader(false)
		}

	}, [props.cart.cartNotAddedAuth]);

	const addToCart = () => {
		let selectedSKU = '';
		let count = 0;
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
	
		if (productWithId) {
			let productData = {
				id: productWithId.productId,
				price: productWithId.price,
				quantity: selectedVariation.quantity,
				variationName: productWithId.variationName
			}
			setSelectedVariation({ ...selectedVariation, productData })
		}

		product?.variations?.map((itemVar, index) => {
			

			if (selectedVariation.id == itemVar._id) {
				
				selectedSKU = itemVar._id;
			}
		})

		if (selectedSKU) {
			product?.licenses?.map((itemLic, index) => {
				

				if (itemLic.productVariationId == selectedSKU) {
					count++
				}
			})
		}

		if (count >= selectedVariation.quantity) {
			if (product.variations.length > 0) {

				if (!selectedVariation.id) {
					setVariationError(true)
				}
				else {
					setVariationError(false)

					
					setCart(product, selectedVariation.price, selectedVariation.id, selectedVariation.quantity, selectedVariation.variationName)
					updateCart()

					let cart = localStorage.getItem("cart")
					cart = cart ? JSON.parse(cart) : [];

					if (userData?._id) {	//If user is logged in 
						cart.map((item, index) => {
							
							cart[index].unitSalesPrice = cart[index].salesPrice
							cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
						})

						let cartParams = {
							emailLanguage: localStorage.getItem('language'),
							userId: userData._id,
							isCheckout: false,
							items: cart
						}
						setLoader(true)

						localStorage.setItem("cart", JSON.stringify(cart))
						props.addToUserCart(cartParams)
					}
					handleShow();
				}
			}
			else {

				setCart()
				updateCart()
				let cart = localStorage.getItem("cart")
				cart = cart ? JSON.parse(cart) : [];
				if (userData) {	//If user is logged in 
					cart.map((item, index) => {
						cart[index].unitSalesPrice = cart[index].salesPrice
						cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
					})

					let cartParams = {
						emailLanguage: localStorage.getItem('language'),
						userId: userData._id,
						isCheckout: false,
						items: cart
					}

					localStorage.setItem("cart", JSON.stringify(cart))
					props.addToUserCart(cartParams)
				}


				handleShow();
			}

		} else {


			let cart = localStorage.getItem("cart")
			cart = cart ? JSON.parse(cart) : [];

			let emailData = {
				emailLanguage: localStorage.getItem('language'),
				userId: userData?._id,
				product: product,
				variationName: selectedVariation.variationName,
				licenseCount: count,
				quantity: selectedVariation.quantity,
			}
			props.addToUserCart(emailData)

			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});

		}

	}

	const setCart = () => {

		let cart = localStorage.getItem("cart")
		cart = cart ? JSON.parse(cart) : [];


		let addNew = true
		cart.forEach(item => {
			if (item.productId == product._id && item.variationId == selectedVariation.id) {
				item.quantity = selectedVariation.quantity
				addNew = !addNew
			}
		})
		if (addNew) {
			let selectedProduct = {
				productId: product._id,
				name: product.name,
				shortDescription: product.shortDescription,
				image: JSON.parse(product.Image),
				variationName: selectedVariation.variationName ? selectedVariation.variationName : '',
				variationId: selectedVariation.id ? selectedVariation.id : '',
				quantity: selectedVariation.quantity,
				price: selectedVariation.price + (selectedVariation.price * vatPercentage / 100),
				salesPrice:checkUserPackagePrice(product?._id,selectedVariation.price),
				discountPercentage: 0,
				licenses: licenses,
				totalLicenses: selectedVariation?.totalLicenses
			}
			cart.push(selectedProduct)
		}
		localStorage.setItem("cart", JSON.stringify(cart))
	}
	let { customername } = ENV.getUserKeys();


	const updateCart = () => {

		let subTotal = 0
		let discount = 0
		let tax = 0
		let total = 0

		cart.map((item) => {
			let subTotal_ = item.quantity * item.salesPrice		//	SalesPrice * Quantity
			subTotal += subTotal_
			let discountPercentage = item.discountPercentage ? item.discountPercentage : 0	// DiscountPercentage
			let discountedPrice = subTotal_ - (subTotal_ * discountPercentage / 100)	// (SalesPrice * Quantity) - Discount
			let taxAmount = (discountedPrice * vatPercentage / 100)

			discount += subTotal_ * discountPercentage / 100
			item.price = discountedPrice + taxAmount
			tax += taxAmount
		})
		total = subTotal - discount + tax

		setCartTotals({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		})
		localStorage.setItem('cartTotal', JSON.stringify({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		}))

		if (userData?._id) {	//If user is logged in 
			cart.map((item, index) => {
				cart[index].unitSalesPrice = cart[index].salesPrice
				cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
			})

			let cartParams = {
				userId: userData._id,
				isCheckout: false,
				items: cart
			}
			props.addToUserCart(cartParams)
		}

	}
	useEffect(() => {
	}, [selectedVariation])
	useEffect(() => {
		if (variationOptions.length > 0) {
			setSelectedVariation({ ...selectedVariation, id: variationOptions[0]?.value, price: variationOptions[0]?.price, variationName: variationOptions[0]?.label })
		}
	}, [variationOptions])
	const incrementInAddToCar = async (value) => {
	

		let selectedSKU = '';
		let count = 0;
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
		if (productWithId) {
			let productData = {
				id: productWithId.productId,
				price: productWithId.price,
				quantity: productWithId.quantity + value,
				variationName: productWithId.variationName
			}
			setSelectedVariation({ ...selectedVariation, quantity: selectedVariation.quantity + value })
		} else {
			setSelectedVariation({ ...selectedVariation, quantity: selectedVariation.quantity + value })
		}

		product?.variations?.map((itemVar, index) => {
			if (selectedVariation.id == itemVar._id) {
				selectedSKU = itemVar._id;
			}
		})

		if (selectedSKU) {
			product?.licenses?.map((itemLic, index) => {
				if (itemLic.productVariationId == selectedSKU) {
					count++
				}
			})
		}

		if (count >= selectedVariation.quantity + 1) {

		} else {
			let cartData = localStorage.getItem("cart")
			cartData = cartData ? JSON.parse(cartData) : [];
			const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
			if (productWithId) {
				let productData = {
					id: productWithId.productId,
					price: productWithId.price,
					quantity: productWithId.quantity,
					variationName: productWithId.variationName
				}
				setSelectedVariation({ ...selectedVariation, productData })
			} else {
				setSelectedVariation({ ...selectedVariation, quantity: selectedVariation.quantity })
			}

			let cart = localStorage.getItem("cart")
			cart = cart ? JSON.parse(cart) : [];
			let emailData = {
				emailLanguage: localStorage.getItem('language'),
				userId: userData?._id,
				product: product,
				variationName: selectedVariation.variationName,
				licenseCount: count,
				quantity: selectedVariation.quantity,
			}
			props.addToUserCart(emailData)

			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});

		}

	}
	const bulkIncrementInAddToCar = async (value) => {
		

		let selectedSKU = '';
		let count = 0;
		let cartData = localStorage.getItem("cart")
		cartData = cartData ? JSON.parse(cartData) : [];
		const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
		if (productWithId) {
			let productData = {
				id: productWithId.productId,
				price: productWithId.price,
				quantity: value,
				variationName: productWithId.variationName
			}
			setSelectedVariation({ ...selectedVariation, productData })
		} else {
			setSelectedVariation({ ...selectedVariation, quantity: value })
		}

		product?.variations?.map((itemVar, index) => {
			if (selectedVariation.id == itemVar._id) {
				selectedSKU = itemVar._id;
			}
		})

		if (selectedSKU) {
			product?.licenses?.map((itemLic, index) => {
				if (itemLic.productVariationId == selectedSKU) {
					count++
				}
			})
		}

		if (count >= value) {
			setSelectedVariation({ ...selectedVariation, quantity: value })
		} else {
			let cartData = localStorage.getItem("cart")
			cartData = cartData ? JSON.parse(cartData) : [];
			const productWithId = cartData.find(product => product.productId === window.location.pathname.split('/')[2]);
			if (productWithId) {
				let productData = {
					id: productWithId.productId,
					price: productWithId.price,
					quantity: value,
					variationName: productWithId.variationName
				}
				setSelectedVariation({ ...selectedVariation, productData })
			} else {
				setSelectedVariation({ ...selectedVariation, quantity: value })
			}

			let cart = localStorage.getItem("cart")
			cart = cart ? JSON.parse(cart) : [];
			let emailData = {
				emailLanguage: localStorage.getItem('language'),
				userId: userData?._id,
				product: product,
				variationName: selectedVariation.variationName,
				licenseCount: count,
				quantity: value,
			}
			props.addToUserCart(emailData)

			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});

		}

	}


	return (
		<Fragment>
			{customername ? <AuthHeader /> : <Header />}
			<div id="main" ref={myRef}>
				{product ?
					<section className="content-section mt-5 mb-5 product-detail-page" id="shop-page">
						<form id="addToCart" method="post" >
							<input type="hidden" name="product_id" />
							<Container>
								<Row className="clearfix">
									<Col lg="6" md="12" xs="12">
										<Row>
											<Col lg="2" md="2" xs="2"></Col>
											<Col lg="8" md="12" xs="12">
												<div className="feature-product-img">
													<SimpleSlider images={product.eccomerceImages} />
												</div>
											</Col>
											<Col lg="2" md="2" xs="2"></Col>
										</Row>
									</Col>
									<Col lg="6" md="12" xs="12">
										<div className="product-details ">

											<div className="d-flex flex-row justify-content-between align-items-start product-title-price mb-2">
												<h3 className="d-flex justify-content-between align-items-center product_price_label font-weight-bold">
													{product.name}
												</h3>
											 
											
											    <span title="Price Inclusive Tax" className="price-area">
													{product.initialPrice == product.lastPrice ? currencyFormat(checkUserPackagePrice(product._id,averageFormat(product.initialPrice, vatPercentage))) : ''}
													{product.initialPrice < product.lastPrice ? variationCurrencyFormat(averageFormat(product.initialPrice, vatPercentage), averageFormat(product.lastPrice, vatPercentage), currency.code, currency.symbol,product?._id) : ''}
												</span>
											
												
											</div>
											{/* <small className="vat d-flex justify-content-end w-100">{translations['Inclusive of']} {vatPercentage}% {vatLabel}</small> */}
											<div className="product-details-bottom-section mt-3 mb-3 ps-lg-0 " dangerouslySetInnerHTML={{ __html: product.shortDescription && product.shortDescription != "undefined" ? product.shortDescription : '' }}>
											</div>
											<div className="panel-group custom-panel-style variant-select-des mb-3" id="accordion">
												<h3 className="d-flex justify-content-between align-items-center product_price_label">{translations['Choose Variant']}</h3>


												<Select
													placeholder={translations['Select Product Type']}
													options={variationOptions}
													defaultValue={variationOptions[0]}
													isDisabled={variationOptions.length === 1 ? true : false}
													name="productType"
													onChange={(option) => {
														setSelectedVariation({ ...selectedVariation, id: option.value, price:option.price , variationName: option.label })
													}
													}
													value={variationOptions.length === 1 ? variationOptions[0] : variationOptions?.filter(option => option.value === selectedVariation.id)}

												/>

												{
													variationError &&
													<small class="error">{translations['Please select variation']}</small>

												}
												<div id="variation-error" className="animated fadeInDown"></div>
											</div>
											<Row>
												<Col lg="12" md="12" xs="12">
													<div className="cart-quilty-incremnt">
														<button type="button" id="decrement" onClick={(event) => {
															if (selectedVariation.quantity > 1)
																setSelectedVariation({ ...selectedVariation, quantity: selectedVariation.quantity - 1 })
														}}>
															<FontAwesomeIcon icon={faMinus} />
														</button>
														<input type="text" name="quantity" onChange={(event) => bulkIncrementInAddToCar(event.target.value != "" ? parseInt(event.target.value) : 1)} min="1" value={selectedVariation.quantity} maxlength="4" max="9999"
															id="quantity" />

														<button type="button" id="increment" onClick={(event) => incrementInAddToCar(1)}>
															<FontAwesomeIcon icon={faPlus} />
														</button>
													</div>
													<div id="quantity-error" className="invalid-feedback animated fadeInDown"></div>
												</Col>
												<Col lg="6" md="12" xs="12" className="button-center cart-btn-wrap">
													<button type="button" className={lang == "de" ? "main-btn-checkout mb-5" : "main-btn mb-5"} onClick={() => addToCart()}>{translations['Add To Cart']}</button>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>

								<Row className="clearfix">
									<Col lg="12" md="12" xs="12">
										<hr />
									</Col>
									<Col lg="12" md="12" xs="12" className="product-long-description-area" dangerouslySetInnerHTML={{ __html: product.longDescription && product.longDescription != "undefined" ? product.longDescription : '' }}>
									</Col>
								</Row>
							</Container>
						</form>
					</section>
					:
					<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
						<section className="content-section mt-5 mb-5 product-detail-page" id="shop-page">
							<form id="addToCart" method="post" >
								<input type="hidden" name="product_id" />
								<Container>
									<Row className="clearfix">
										<Col lg="6" md="12" xs="12">
											<Row>
												<Col lg="2" md="2" xs="2"></Col>
												<Col lg="8" md="12" xs="12">
													<div className="feature-product-img">
														<Skeleton height={250} width={250} />
													</div>
												</Col>
												<Col lg="2" md="2" xs="2"></Col>
											</Row>
										</Col>
										<Col lg="6" md="12" xs="12">
											<div className="product-details">
												<div className="d-flex flex-row justify-content-between align-items-start product-title-price mb-2">
													<h3 className="d-flex justify-content-between align-items-center product_price_label font-weight-bold">
														<Skeleton count={2} />
													</h3>
													<span title="Price Inclusive Tax" className="price-area">
														<Skeleton count={2} />
													</span>
												</div>
												<small className="vat d-flex justify-content-end w-100"><Skeleton count={1} /></small>
												<div className="product-details-bottom-section mt-3 mb-3 ps-lg-0 " >
													<Skeleton count={2} />
												</div>
												<div className="panel-group custom-panel-style mb-5" id="accordion">
													<Skeleton count={3} />
												</div>
												<Row>
													<Col lg="12" md="12" xs="12">
														<div className="cart-quilty-incremnt">
															<Skeleton count={5} />
														</div>
														<div id="quantity-error" className="invalid-feedback animated fadeInDown"></div>
													</Col>
													<Col lg="6" md="12" xs="12" className="button-center">
														<Skeleton count={1} />
													</Col>
												</Row>
											</div>
										</Col>
									</Row>

									<Row className="clearfix">
										<Col lg="12" md="12" xs="12">
											<hr />
										</Col>
										<Col lg="12" md="12" xs="12">
											<Skeleton count={6} />
										</Col>
									</Row>
								</Container>
							</form>
						</section>
					</SkeletonTheme>
				}
				{loader && <FullPageLoader />}
			</div>
			<Footer />

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false} className="cart-modal">
				<Modal.Header closeButton>
					<h4 className="mb-0"><span className="glyphicon glyphicon-shopping-cart"></span> <b>Cart</b></h4>
				</Modal.Header>
				<Modal.Body>
					<Cart />
				</Modal.Body>

			</Modal>
		</Fragment>
	)
}

const mapStateToProps = state => ({
	errors: state.errors,
	siteSetting: state.siteSetting,
	product: state.product,
	cart: state.cart
})

export default connect(mapStateToProps, { beforeProducts, getProduct, addToUserCart, beforeCart, getVATforCountry })(ProductDetail);