import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'

const Template = () => {
    return (
        <div className='wrapper'>
            <Header />
            <div className='template-wrapper'>
                <div className='template-banner'>
                    <div className='container-box'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-lg-6'>
                                <div className='template-banner-content'>
                                    <h5>ALL-IN-ONE</h5>
                                    <h2>TRUE CYBER PROTECTION</h2>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='template-banner-img'>
                                    <img src='https://attivita.arhamsoft.org/static/media/shop-banner.bd650ce2.png' alt="banner-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section-wrapper'>
                    <div className='container-box'>
                        <div className='section-heading'>
                            <h1>Lorem ipsum</h1>
                            <h6>Lorem ipsum dolor sit amet</h6>
                        </div>
                        <div className='template-boxes'>
                            <div className='row'>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Lorem ipsum</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Kerop Kosle</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Lofsak Oprkaooo</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Massive Lakdoo</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Lorem ipsum</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Kerop Kosle</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Lofsak Oprkaooo</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-4 boxes-height'>
                                    <div className='template-boxes-content'>
                                        <div className='template-boxes-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='box-img' />
                                        </div>
                                        <h4>Massive Lakdoo</h4>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='template-info'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-lg-7'>
                                    <div className='template-info-content'>
                                        <h3>Lorem ipsum</h3>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. <br /> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='template-info-video'>
                                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/B-ytMSuwbf8" title="YouTube video player" frameborder="0" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='template-info template-last-info'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-lg-5'>
                                    <div className='template-info-img'>
                                        <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='template-info-img' />
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div className='template-info-content'>
                                        <h3>Lorem ipsum</h3>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. <br /> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='template-form-wrapper'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-lg-6'>
                                    <div className='template-form-content'>
                                        <div className='section-heading'>
                                            <h1>Lorem ipsum</h1>
                                            <h6>Lorem ipsum dolor sit amet</h6>
                                        </div>
                                        <div className='template-form-content-img'>
                                            <img src='https://attivita.arhamsoft.org/images/circle-image.png' alt='form-img' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <form>
                                        <div className="mb-3 input-wrapper">
                                            <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp"/>
                                        </div>
                                        <div className="mb-3 input-wrapper">
                                            <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp"/>
                                        </div>
                                        <div className="mb-3 input-wrapper">
                                            <input type="text" className="form-control" placeholder='Lorem ipsum' id="exampleInputEmail1" aria-describedby="emailHelp"/>
                                        </div>
                                        <div className="mb-4 input-wrapper">
                                            <textarea className="form-control" placeholder="Lorem ipsum" id="floatingTextarea2" rows="5"></textarea>
                                        </div>
                                        <button type="submit" className="btn-blue">Lorem</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Template