import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import closeMenuImg from '../../assets/images/x.svg'
import menuIcon from '../../assets/images/menu.svg'
import logoImg from '../../assets/images/logo.svg';
import userImg from '../../assets/images/user-icon.svg'
import usaImg from '../../assets/images/usa.svg';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { ENV } from "../../config/config";
import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { connect } from 'react-redux';
import { predefinedContent } from "./content";
import { beforeSettings, getSettings } from '../siteSettings/siteSettings.action';
import { getHeaderContent, beforeContent } from "../content/content.action";
import { beforeCheckout, getUser, getLanguages } from "../checkout/checkout.action";
import Cart from "../cart/Cart";


import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { getMenu, beforeCMS,getHeaderMenu } from '../CMS/CMS.action'
const Header = (props) => {

  const [languages, setLanguages] = useState('');

  let pathName = window.location.pathname
  let userData = ENV.getUserKeys();
  const [click, setClick] = useState(false);
  const [logo, setLogo] = useState();
  const [topHeadline, setTopHeadline] = useState([]);
  const handleClick = () => setClick(!click);
  const [pageContent, setPageContent] = useState(predefinedContent);
  const closeMobileMenu = () => setClick(false);
  const [show, setShow] = useState(false);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'de');
  const history = useHistory();
  const [headerMenus, setHeaderMenus] = useState([])
  const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
  let translations = new LocalizedStrings(localizations);
  translations.setLanguage(lang);


  useEffect(() => {
    props.getSettings()
    props.getLanguages()

    if (!props.content.getHeaderContent) {
      props.getHeaderContent('header')
      props.getHeaderMenu('header')
    }
  }, [])

  useEffect(() => {
    if (props.cms.getHeaderMenu) {
      setHeaderMenus(props.cms.headerMenus)
      props.beforeCMS()
    }
  }, [props.cms.getHeaderMenu]);
  useEffect(() => {
    if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
      translations.setLanguage(localStorage.getItem('language'));
      setLang(localStorage.getItem('language'))
      setSelectedLanguage(localStorage.getItem('language'))
    }
  }, [localStorage.getItem('language')])

  useEffect(() => {
    if (props.checkout.getLanguagesAuth) {
      const { languages } = props.checkout.languages
      setLanguages(languages)
    }
  }, [props.checkout.getLanguagesAuth])

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [])
  }, [localStorage.getItem("cart")]);


  useEffect(() => {
    if (props.siteSetting.getSettings) {
      setTopHeadline(props.siteSetting.settings)
      setLogo(props.siteSetting.settings.siteLogo)
    }
  }, [props.siteSetting.getSettings]);

  useEffect(() => {
    if (props.content.getHeaderContent) {
      const { content } = props.content.headerContent
      if (content) {
        setPageContent(content.content)
      }



      props.beforeContent()
    }
  }, [props.content.getHeaderContent])

  const setSiteLang = (name, code) => {
    localStorage.setItem('language', code)
    setSelectedLanguage(code)

    var the_arr = window.location.pathname.split('/');
    the_arr.pop();
    the_arr = the_arr.join('/');
    the_arr = the_arr + `/` + code
    history.push(the_arr)
    window.location.reload()

  }

  return (
    <Fragment>
        <div className="top-header">
          <ul>
            <li>
              <p>{lang == 'de' ? topHeadline.leftDE : topHeadline.left}</p>
            </li>
            <li>
              <p>{lang == 'de' ? topHeadline.middleDE : topHeadline.middle}</p>
            </li>
            <li>
              <p>{lang == 'de' ? topHeadline.rightDE : topHeadline.right}</p>
            </li>
          </ul>
        </div>
        <header id="header" className="header">
          <nav className="navbar header-navbar navbar-expand-lg navbar-light">
            <Container fluid>
              <strong className="logo">
                <Link to={`/${lang}`}>
                  <img src={logoImg} className="img-fluid" alt="main-logo" title="logo" />
                </Link>
              </strong>
              <div className={click ? "nav-options active" : "nav-options"}>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    {/* <Link to={`/${lang}`} className={`nav-link ${pathName === `/${lang}` ? "active" : ""} `}>{translations["Home"]}</Link> */}
                    <Link to={`/${lang}`} className={`nav-link ${pathName === `/${lang}` ? "active" : ""} `}>{translations["Home"]}</Link>

                  </li>
                  <li className="nav-item">
                    <Link to={`/shop/${lang}`} className={`nav-link ${pathName === `/shop/${lang}` ? "active" : ""} `}>{translations["Shop"]}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={`/aboutus/${lang}`} className={`nav-link ${pathName === `/aboutus/${lang}` ? "active" : ""} `}>{translations["About Us"]}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={`/contactus/${lang}`} className={`nav-link ${pathName === `/contactus/${lang}` ? "active" : ""} `}>{translations["Contact Us"]}</Link>
                  </li>
                  {
                    headerMenus && headerMenus.length > 0 &&
                    headerMenus.map((menu, index) => (
                      <li className="nav-item" key={index}>
                        <Link className="nav-link" target='_blank' to={`/page/` + menu.slug + `/` + lang}>{lang == "de" ? menu.germanTitle : menu.title}</Link>
                      </li>

                    ))
                  }
                  <div className="my-account hide-my-account">
                    {/* <div className="cart-icon-holder">
                    <FontAwesomeIcon icon={faBagShopping} onClick={()=>handleShow()} />
                  </div> */}
                    <div className="my-account-filter">
                      <div id="country" className="d-flex align-items-center">
                        <Dropdown>
                          <Dropdown.Toggle variant="default" id="dropdown-basic">
                            <div className="country-selection-img">
                              <img src={'/' + selectedLanguage + '.svg'} className="img-fluid" alt="country" title="" />
                              <span>{selectedLanguage}</span>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul>
                              {languages && languages.map(lang =>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <Dropdown.Item><span id="lang-name" title={lang.name} onClick={() => setSiteLang(lang.name, lang.iso_code)}>{lang.name}</span></Dropdown.Item>
                                    <span id="flag-img">
                                      <img src={'/' + lang.iso_code + '.svg'} className="img-fluid" alt="country" title="" />
                                    </span>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {/* <div id="currency" className="d-flex align-items-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                          <span className="account-text-btn">$ - USD</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <ul>
                            <li><a className="dropdown-item" href="#">€ - EUR</a></li>
                            <li><a className="dropdown-item" href="#">лв - BGN</a></li>
                            <li><a className="dropdown-item" href="#">R$ - BRL</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                            <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                    </div>
                    <Link to={`/register/${lang}`} className="my-account-seller">
                      <button type="button">{translations['Become a Reseller']}</button>
                    </Link>
                    <Link to={`/login/${lang}`} className="my-account-login">
                      <button type="button" className="account-text-btn">{translations['Login']}</button>
                      <button type="button" className="account-icon-btn">
                        <FontAwesomeIcon icon={faRightToBracket} />
                      </button>
                    </Link>
                  </div>
                </ul>
              </div>
              <div className="my-account show-my-account">
                <div className="cart-icon-holder position-relative">
                  <FontAwesomeIcon icon={faBagShopping} onClick={() => handleShow()} title="Cart" />
                  <span className="cart-qty count my-count">{cart.length}</span>
                </div>
                <div className="my-account-filter">
                  <div id="country" className="d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle variant="default" id="dropdown-basic">
                        <div className="country-selection-img">
                          <img src={'/' + selectedLanguage + '.svg'} className="img-fluid" alt="country" title="" />
                          <span>{selectedLanguage}</span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul>
                          {languages && languages.map(lang =>
                            <li>
                              <a className="dropdown-item" href="#">
                                <span id="lang-name" title={lang.name} onClick={() => setSiteLang(lang.name, lang.iso_code)}>{lang.name}</span>
                                <span id="flag-img">
                                  <img src={'/' + lang.iso_code + '.svg'} className="img-fluid" alt="country" title="" />
                                </span>
                              </a>
                            </li>
                          )}
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {/* <div id="currency" className="d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                      <span className="account-text-btn">$ - USD</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ul>
                        <li><a className="dropdown-item" href="#">€ - EUR</a></li>
                        <li><a className="dropdown-item" href="#">лв - BGN</a></li>
                        <li><a className="dropdown-item" href="#">R$ - BRL</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                        <li><a className="dropdown-item" href="#">Kč - CZK</a></li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                </div>
                <Link to={`/login/${lang}`} className="my-account-login">
                  <button type="button" className="account-text-btn">{translations['Login']}</button>
                  <button type="button" className="account-icon-btn">
                    <FontAwesomeIcon icon={faRightToBracket} />
                  </button>
                </Link>
                <Link to={`/register/${lang}`} className="my-account-seller">
                  <button type="button">{translations['Become a Reseller']}</button>
                </Link>
              </div>
            </Container>
          </nav>
          <div className="login-menu-m">
            <div className="cart-icon-holder position-relative">
              <FontAwesomeIcon icon={faBagShopping} onClick={() => handleShow()} title="Cart" />
              <span className="cart-qty count my-count">{cart.length}</span>
            </div>
            <div className="mobile-menu" onClick={handleClick}>
              {click ? (
                <img src={closeMenuImg} className="menu-icon" />
              ) : (
                <img src={menuIcon} className="menu-icon" />
              )}
            </div>
          </div>
        </header>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false} className="cart-modal">
          <Modal.Header closeButton>
            <h4 className="mb-0"><span className="glyphicon glyphicon-shopping-cart"></span> <b>{translations['Cart']}</b></h4>
          </Modal.Header>
          <Modal.Body>
            <Cart />
          </Modal.Body>

        </Modal>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  siteSetting: state.siteSetting,
  checkout: state.checkout,
  content: state.content,
  cms: state.cms
})
export default connect(mapStateToProps, { beforeSettings, getSettings, getHeaderContent, beforeContent, beforeCheckout, getLanguages, getHeaderMenu, beforeCMS })(Header);