import React, { Component, Fragment, useState } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col, Pagination  } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import "./TablePagination.css";


const TablePagination = (props) => {
	return (
			<Pagination>
				<Pagination.First />
				<Pagination.Prev />
				<Pagination.Item>{1}</Pagination.Item>
				<Pagination.Ellipsis />

				<Pagination.Item>{10}</Pagination.Item>
				<Pagination.Item>{11}</Pagination.Item>
				<Pagination.Item active>{12}</Pagination.Item>
				<Pagination.Item>{13}</Pagination.Item>
				<Pagination.Item disabled>{14}</Pagination.Item>

				<Pagination.Ellipsis />
				<Pagination.Item>{20}</Pagination.Item>
				<Pagination.Next />
				<Pagination.Last />
			</Pagination>
	)
}


export default TablePagination;