
import { ENV } from "../config/config";

export const currencyFormat = (amount, currencyCode = "EUR", currencySymbol = "€", productId) => {
    return parseFloat(amount).toFixed(2) + " " + currencyCode
}
export const variationCurrencyFormat = (amount1, amount2, currencyCode = "EUR", currencySymbol = "€", productId) => {
    return parseFloat(checkUserPackagePrice(productId, amount1)).toFixed(2) + " - " + parseFloat(checkUserPackagePrice(productId, amount2)).toFixed(2) + " " + currencyCode
}
export const checkUserPackagePrice = (productId, amount) => {

    let userPackage = ENV.getUserKeys('packageId');

    if (Object.keys(userPackage).length > 0 && userPackage?.packageId) {
        let accumulateAmount = 0;
        userPackage = userPackage.packageId
        if (userPackage?.rules?.length > 0) {
            //  1: All, 2: Specific Product, 3: Specific Variation
            const hasTypeOfAllProduct = userPackage?.rules?.find(obj => obj.type === 1);
            if (hasTypeOfAllProduct === undefined) {
                const highestPercentageObject = userPackage?.rules
                    .filter(obj => obj.productId === productId)
                    .reduce((highestObject, obj) => {
                        if (!highestObject || obj.percentage > highestObject.percentage) {
                            return obj; // Store the object with the highest percentage.
                        } else {
                            return highestObject; // Keep the current highestObject.
                        }
                    }, null);
                if (highestPercentageObject && Object.keys(highestPercentageObject).length > 0) {
                    //find the percentage 
                    //packageType 1=Fixed 0=percentage
                    if (highestPercentageObject.packageType === 1) {
                        
                        //1=incremented 0=decremented
                        if (highestPercentageObject.modelType === 1) {
                            accumulateAmount = highestPercentageObject.percentage + (amount);
                        } else if (highestPercentageObject.modelType === 0) {
                            accumulateAmount = (amount) - highestPercentageObject.percentage;
                        }
                    } else {
                        let percentageAmount = ((amount) / 100) * highestPercentageObject.percentage;
                        //1=incremented 0=decremented
                        if (highestPercentageObject.modelType === 1) {
                            accumulateAmount = percentageAmount + (amount);
                        } else if (highestPercentageObject.modelType === 0) {
                            accumulateAmount = (amount) - percentageAmount;
                        }
                    }


                    return accumulateAmount;

                } else {
                    return amount
                }
            } else {

                if (hasTypeOfAllProduct.packageType === 1) {
                    //1=incremented 0=decremented
                    if (hasTypeOfAllProduct.modelType === 1) {
                        accumulateAmount = hasTypeOfAllProduct.percentage + (amount);
                    } else if (hasTypeOfAllProduct.modelType === 0) {
                        accumulateAmount = amount - hasTypeOfAllProduct.percentage;
                    }
                } else {
                    let percentageAmount = ((amount) / 100) * hasTypeOfAllProduct.percentage;
                    //1=incremented 0=decremented
                    if (hasTypeOfAllProduct.modelType === 1) {
                        accumulateAmount = percentageAmount + (amount);
                    } else if (hasTypeOfAllProduct.modelType === 0) {
                        accumulateAmount = (amount) - percentageAmount;
                    }
                }
                return accumulateAmount;
            }

        } else {
            //check type of model
            //find the percentage 
            //packageType 1=Fixed 0=percentage
            if (userPackage.packageType === 1) {
                
                //1=incremented 0=decremented
                if (userPackage.modelType === 1) {
                    accumulateAmount = userPackage.percentage + parseInt(amount);
                } else if (userPackage.modelType === 0) {
                    accumulateAmount = parseInt(amount) - userPackage.percentage;
                }
            } else {
                let percentageAmount = (parseInt(amount) / 100) * userPackage.percentage;
                //1=incremented 0=decremented
                if (userPackage.modelType === 1) {
                    accumulateAmount = percentageAmount + parseInt(amount);
                } else if (userPackage.modelType === 0) {
                    accumulateAmount = parseInt(amount) - percentageAmount;
                }
            }

        }
        //  1: All, 2: Specific Product, 3: Specific Variation
        //1=incremented 0=decremented
        return accumulateAmount
    }
    return amount;

}
export const averageFormat = (amount1, amount2) => {

    return parseFloat(amount1)
}