import React, { useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col, Button,Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { beforeDashboard, getDashboardListing } from "./dashboard.action";
import Header from '../header/Header';
import AuthHeader from "../authheader/AuthHeader";
import Footer from '../footer/Footer';
import "./Dashboard.css";
import { ENV } from "../../config/config";
import { currencyFormat } from "../../utils/functions"
import moment from 'moment'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import LocalizedStrings from "react-localization";
import localizations from '../../localization';

const SalesOrdersListing = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(() => {
		if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
			translations.setLanguage(localStorage.getItem('language'));
			setLang(localStorage.getItem('language'))
		}
	}, [localStorage.getItem('language')])


	let history = useHistory()
	let userData = ENV.getUserKeys();
	const [salesOrders, setSalesOrders] = useState([])
	const [Page, setPage] = useState(1)
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(null)

	useEffect(() => {
		props.getDashboardListing(userData._id, 1)
	}, []);

	useEffect(() => {
		if (props.dashboard.getListingAuth) {
			setSalesOrders(props.dashboard.data.data.orders)
			setPagination(props.dashboard.data.data.pagination)
		}
	}, [props.dashboard.getListingAuth]);

	const onPageChange = async (page) => {
		setPage(page)
		const qs = ENV.objectToQueryString({ page: page, limit: limit })
		props.getDashboardListing(userData._id, 1, qs)
	}
	const limitOptions = [5, 10, 20, 50, 100, 150, 250];
	const onChangeLimit = (value) => {
		setLimit(value)
		let filter = {};
		setPage(1)
	
		const qs = ENV.objectToQueryString({ page: Page, limit: value })
		props.getDashboardListing(userData._id, 1, qs)
	}
	let { customername } = ENV.getUserKeys();
	return (
		<div>
			{customername ? <AuthHeader /> : <Header />}

			<div id="main">
				<div className="row dark-green div-breadcrumbs" style={{ background: 'rgb(146 132 250)', color: 'white', padding: '10px' }}>
					<div className="container">
						<div>
							<Link style={{ color: 'white', 'font-weight': '500' }} to={"/dashboard/" + lang}>{translations['Dashboard']}</Link> /
							{translations['Sales Orders']}
						</div>
					</div>
				</div>
				<section className="content-section mt-3 mb-3" id="account-page">
					<Container>
						<Row className="mt-2 bottom-space">
							<Col lg="12" sm="12">
								<h3 className="mt-3 mb-3">{translations['Sales Orders']}</h3>
								<div id="sales-datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
									<div className="table-responsive">
										<table id="sales-datatable" class="table table-bordered table-striped dataTable no-footer dtr-inline">
											<thead>
												<tr role="row">
													<th>{translations['Sales Order']} #</th>
													<th>{translations['Order Date']}</th>
													<th>{translations['Total']}</th>
												</tr>
											</thead>
											<tbody>
												{
													salesOrders.length > 0 ?
														salesOrders.map((quotation, index) => {
															return (
																<tr role="row" key={index} onClick={() => history.push('/quotationdetail/' + quotation._id + "/" + lang)}>
																	<td>{"AVS" + quotation.orderNumber.toString().padStart(5, 0)}</td>
																	<td>{moment(quotation.createdAt).format('MM-DD-YYYY')}</td>
																	<td>{currencyFormat(quotation.grandTotal)}</td>
																</tr>
															)
														})
														:
														<tr role="row">
															<td colspan="3">{translations['No order found']}</td>
														</tr>
												}
											</tbody>
										</table>
									</div>
									<div className="pb-4">
										{pagination &&
											<div className='pagination-wrapper'>
												<Pagination
													className="m-3"
													defaultCurrent={Page}
													pageSize // items per page
													current={Page > pagination.pages ? pagination.pages : Page} // current active page
													total={pagination.pages} // total pages
													onChange={onPageChange}
													
												/>
												<div className='select-form-wrapper'>
													<label className='remove-margin' style={{ color: 'black' }}>  Showing Records {pagination?.total === 0 ? 0 : ((Page - 1) * limit + 1)} to {Math.min(((Page - 1) * limit) + limit, pagination?.total)} out of {pagination?.total}</label>
													<Form.Group>
														<select value={limit} onChange={(e) => onChangeLimit(e.target.value)}>

															{limitOptions?.map(limitOption => {
																return (
																	<>
																		<option value={limitOption}>{limitOption}</option>
																	</>
																)
															})}
														</select>
													</Form.Group>

												</div>
											</div>
										}
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</div>
	)
}

const mapStateToProps = state => ({
	dashboard: state.dashboard
})
export default connect(mapStateToProps, { beforeDashboard, getDashboardListing })(SalesOrdersListing);