import React, { Fragment, useEffect, useState } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col, Tab, Tabs, TabContent, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import productImg2 from '../../assets/images/products-device.png'
import productImg3 from '../../assets/images/products-office.png'
import { getProductsList, beforeProducts, getCategories } from "./product.action";
import { getVATforCountry } from "../siteSettings/siteSettings.action";
import { connect } from 'react-redux';
import { variationCurrencyFormat, currencyFormat, averageFormat, checkUserPackagePrice } from "../../utils/functions"
import { ENV } from "../../config/config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Product.css";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faRefresh, faWarning } from "@fortawesome/free-solid-svg-icons";

const Product = (props) => {

	const [isActive, setIsActive] = useState(false);
	const handleClick = event => {
		setIsActive(current => !current);
	};


	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);





	let user = ENV.getUserKeys();
	const [products, setProducts] = useState([])
	const [manufacturers, setManufacturers] = useState([])
	const [manufacturerId, setManufacturerId] = useState(null)
	const [manufacturersLoader, setManufacturersLoader] = useState(false);
	const [productsLoader, setProductLoader] = useState(false);
	const [activeManufacturer, setActiveManufacturer] = useState('');
	const [vatPercentage, setVatPercentage] = useState(16)
	const [showAllProducts, setShowAllProducts] = useState(true)
	const [searchEventKey, setSearchEventKey] = useState(false)
	const [againProduct, setAgainProduct] = useState(false)
	const [show, setShow] = useState(false);
	const [categoryId, setCategoryId] = useState(null)
	const [currency, setCurrency] = useState({
		symbol: "€",
		code: "EUR"
	})
	const [categories, setCategories] = useState(null)
	const getProductByCategory = (e, id) => {
		e.preventDefault();
		setCategoryId(id)
		setProductLoader(false)
		setManufacturersLoader(false)
		setProducts([])
		setSearchEventKey(true)
		setShowAllProducts(true)
		props.beforeProducts()
		let resellers = ENV.getUserKeys();
		const qsProduct = ENV.objectToQueryString({ bestSeller: false, categoryId: id, resellerId: resellers?._id })
		props.getProductsList(qsProduct)
	}
	useEffect(() => {
		let resellers = ENV.getUserKeys();
		const qsProduct = ENV.objectToQueryString({ bestSeller: false, resellerId: resellers?._id })
		props.getProductsList(qsProduct)
		const qsCategory = ENV.objectToQueryString({ all: 1, page: 1, limit: 50, status: true })
		props.getCategories(qsCategory);
	}, [])
	useEffect(() => {

		if (props.product.getCategoriesAuth) {
			let { categories } = props.product.categories
			setCategories(categories)
		}
	}, [props.product.getCategoriesAuth])
	useEffect(() => {
		if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
			translations.setLanguage(localStorage.getItem('language'));
			setLang(localStorage.getItem('language'))
		}
	}, [localStorage.getItem('language')])
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	useEffect(() => {
		if (setAgainProduct) {
			let resellers = ENV.getUserKeys();
			const qsProduct = ENV.objectToQueryString({ bestSeller: false, resellerId: resellers?._id })
			props.getProductsList(qsProduct)

		}
	}, [])


	//  for change beacuse of search props
	useEffect(() => {
		if (props.searchProductsFilter)
			setProducts(props.searchProductsFilter.selected)
		setProductLoader(true);
		setSearchEventKey(true)
		setShowAllProducts(true)
	}, [props.searchProductsFilter.selected])

	useEffect(() => {
		if (props.siteSetting.getSettings) {
			if (user?.addresses && user?.addresses?.length > 0) {
				props.getVATforCountry(user?.addresses[0]?.country)
			}
			else {
				setVatPercentage(props.siteSetting.settings.vatPercentage)
			}
		}
	}, [props.siteSetting.getSettings]);
	useEffect(() => {
	})
	useEffect(() => {
		if (props.siteSetting.getCountry) {
			setVatPercentage(props.siteSetting.vat)
		}
	}, [props.siteSetting.getCountry]);

	useEffect(() => {
		if (props.product.getproductsList) {
			setManufacturers(props.product.productsList.manufacturers)
			setProducts(props.product.productsList.products)
			setProductLoader(true)
			setManufacturersLoader(true)
		}
	}, [props.product.getproductsList]);
	const resetAllState = () => {
		props.beforeProducts()
		setProductLoader(false)
		setManufacturersLoader(false)
		setCategoryId(null)
		let resellers = ENV.getUserKeys();
		const qsProduct = ENV.objectToQueryString({ bestSeller: false, resellerId: resellers?._id })
		props.getProductsList(qsProduct)
		const qsCategory = ENV.objectToQueryString({ all: 1, page: 1, limit: 50, status: true })
		props.getCategories(qsCategory);
	}
	useEffect(() => {
		// Get the current URL search parameters
		const params = new URLSearchParams(window.location.search);
		// Get the value of the "manufacturerId" parameter
		const manufacturerQueryId = params.get('manufacturerId');
		if (manufacturerQueryId) {
			setManufacturerId(manufacturerQueryId)
			setActiveManufacturer(manufacturerQueryId + '-event')
			setAgainProduct(true); setShowAllProducts(false); setSearchEventKey(false);
		}else{
			setActiveManufacturer('show-all')
		}

	}, [])
	return (
		<section className="products-section custom-container home-product-sec">
			<Container fluid>

				<h2 className="text-center">{translations["Our Products Range"]}</h2>

				{/* <h3 className="text-center">Security Product Framework</h3> */}
				{activeManufacturer &&
					<Tab.Container defaultActiveKey={activeManufacturer}>
						<h3 className="vendor-head-sty">{translations["Choose from Vendors"]}</h3>
						<Row className="mb-5 vendor-row">
							<Col lg="12" md="12" sm="12">
								<Nav variant="pills" className="row flex-row">
									{
										manufacturersLoader ?
											manufacturers.length > 0 ?
												manufacturers.map((manufacturer, index) => {

													return (
														<Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4" key={index} onClick={() => { setAgainProduct(true); setShowAllProducts(false); setSearchEventKey(false); setManufacturerId(manufacturer?._id) }}>
															<Nav.Link eventKey={manufacturer._id + "-event"} className="link-unstyled  vendor-images px-0">
																<div className="products-card d-flex justify-content-center align-items-center flex-column position-relative">
																	<div className="products-same-img vendor-img">
																		<img src={manufacturer.image} className="img-fluid" alt={manufacturer.imageAltText ? manufacturer.imageAltText : manufacturer.name} />
																	</div>
																</div>
															</Nav.Link>
															{/* <div className="products-card-text"><h5 className="text-center">{manufacturer.name}</h5></div> */}
														</Nav.Item>
													)


												}) : <p className="text-danger">{translations['Currently no vendor is available pertaining to your requirement. For further queries, please contact us.']}</p>
											:
											<Fragment>
												<Nav.Item className="col-lg-2">
													<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
														<p>
															<Skeleton count={3} />
														</p>
													</SkeletonTheme>
												</Nav.Item>
												<Nav.Item className="col-lg-2">
													<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
														<p>
															<Skeleton count={3} />
														</p>
													</SkeletonTheme>
												</Nav.Item>

											</Fragment>

									}

								</Nav>

							</Col>
						</Row>

						{/* <h3 className="vendor-head-sty">{translations["Our Products Range"]}</h3> */}
						<div className={`category-product-wrapper mb-5 ${isActive ? "hide-class" : ""}`}>
							{categories &&
								<div className="category-product-sidebar" onClick={handleClick}
								>
									<FontAwesomeIcon icon={faBars} className="toggler-bars"  />
									<ul className="category-product-sidebar-list mt-3 position-relative add-scroll">
										<FontAwesomeIcon className="float-right refresh-icon" onClick={() => { window.location.reload() }} icon={faRefresh} />
										{categories.map(category => {
											if (manufacturerId && category?.manufacturers?.includes(manufacturerId)) {
												return (
													<li><a className={categoryId === category?._id ? 'active' : ''} href="#" onClick={(e) => getProductByCategory(e, category?._id)}>{lang === "en" ? category?.titleEn : category?.titleDe}</a></li>
												)
											} else if (!manufacturerId) {
												return (
													<li><a className={categoryId === category?._id ? 'active' : ''} href="#" onClick={(e) => getProductByCategory(e, category?._id)}>{lang === "en" ? category?.titleEn : category?.titleDe}</a></li>
												)
											}


										})}
									</ul>
								</div>
							}

							<div className="category-product-main-area">
								<Row className="mb-5">
									<Col lg="12" md="12" sm="12">
										{
											productsLoader ?
												showAllProducts ?
													products.length > 0 &&
													<Row className="all_products_row">
														{
															products.map((product, index_) => {

																let allow_display = true
																let initialPrice = 0
																let lastPrice = 0
																initialPrice = averageFormat(product.initialPrice, vatPercentage)
																lastPrice = averageFormat(product.lastPrice, vatPercentage)
																if (window.location.pathname.split('/')[1] == '' && index_ > 7) {
																	allow_display = false
																}
																if (allow_display) {
																	let productLink = "/productdetail/" + product._id + "/" + lang

																	return (
																		<Link to={ENV.checkUserAuth() && productLink} className="link-unstyled col-xl-3 col-lg-4 col-sm-6" onClick={!ENV.checkUserAuth() && handleShow} key={index_}>
																			<div className="products-card all-products-card d-flex justify-content-between align-items-center flex-column position-relative">
																				<div className="product-tags">
																					<span>
																						{localStorage.getItem('attivitaEncryptedUser') && product.skuCount}	{translations["Licenses Available"]}
																					</span>
																					{
																						product.bestSeller && <span>
																							Best Seller
																						</span>

																					}
																				</div>
																				<div className="products-same-img">
																					<img src={product.Image && JSON.parse(product.Image) ? JSON.parse(product.Image) : productImg2} className="img-fluid" alt={product.imageAltText ? product.imageAltText : product.name} />
																				</div>
																				<div className="products-card-text">
																					<h5 title={product.name} className="text-capitalize">{product.name}</h5>
																					{ENV.checkUserAuth() &&
																						<span id="product-price">
																							{product.initialPrice == product.lastPrice ? currencyFormat(checkUserPackagePrice(product._id, initialPrice), currency.code, currency.symbol) : ''}
																							{product.initialPrice < product.lastPrice ? variationCurrencyFormat(initialPrice, lastPrice, currency.code, currency.symbol, product._id) : ''}
																						</span>
																					}
																				</div>
																			</div>
																		</Link>
																	)
																}
															})
														}
													</Row>
													: ''
												:
												<Fragment>
													<Nav.Item className="col-lg-2" >
														<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
															<p>
																<Skeleton count={6} />
															</p>
														</SkeletonTheme>
													</Nav.Item>
													<Nav.Item className="col-lg-2" >
														<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
															<p>
																<Skeleton count={6} />
															</p>
														</SkeletonTheme>
													</Nav.Item>

												</Fragment>
										}
										<Tab.Content className="row product-tabs">
											{
												!searchEventKey ?
													products && products.length > 0 && manufacturers.length > 0 ?
														manufacturers.map((manufacturer, index) => {
															{

																return (
																	<Tab.Pane eventKey={manufacturer._id + "-event"} className="col-lg-12" key={index} >
																		<Row>
																			{
																				manufacturer.products.length > 0 ?
																					manufacturer.products.map((product, index_) => {
																						let product_ = products.filter((p) => {
																							return p._id == product._id;
																						})
																						let allow_display = true
																						let initialPrice = 0
																						let lastPrice = 0

																						initialPrice = averageFormat(product_[0].initialPrice, vatPercentage)
																						lastPrice = averageFormat(product_[0].lastPrice, vatPercentage)
																						if (window.location.pathname.split('/')[1] == '' && index_ > 7) {
																							allow_display = false
																						}
																						if (allow_display) {
																							let productLink = "/productdetail/" + product._id + "/" + lang
																							return (
																								<Link to={ENV.checkUserAuth() && productLink} className="link-unstyled col-xl-3 col-lg-4 col-sm-6" onClick={!ENV.checkUserAuth() && handleShow} key={index_}>
																									<div className="products-card d-flex justify-content-between align-items-center flex-column position-relative">
																										<div className="product-tags">
																											<span>
																												{localStorage.getItem('attivitaEncryptedUser') && product_[0].skuCount}	{translations["Licenses Available"]}
																											</span>
																											{
																												product_[0].bestSeller && <span>
																													Best Seller
																												</span>

																											}
																										</div>
																										<div className="products-same-img">
																											<img src={product_[0].Image && JSON.parse(product_[0].Image) ? JSON.parse(product_[0].Image) : productImg2} className="img-fluid" alt={product_[0].imageAltText ? product_[0].imageAltText : product_[0].name} title="" />
																										</div>
																										<div className="products-card-text">
																											<h5 title={product_[0].name} className="text-capitalize">{product_[0].name}</h5>
																											{ENV.checkUserAuth() &&
																												<span id="product-price">
																													{/* {product.initialPrice == product.lastPrice ? currencyFormat(initialPrice, currency.code, currency.symbol) : ''}
																										{product.initialPrice < product.lastPrice ? variationCurrencyFormat(initialPrice, lastPrice, currency.code, currency.symbol, product?._id) : ''} */}
																													{product.initialPrice == product.lastPrice ? currencyFormat(checkUserPackagePrice(product._id, initialPrice), currency.code, currency.symbol) : ''}
																													{product.initialPrice < product.lastPrice ? variationCurrencyFormat(initialPrice, lastPrice, currency.code, currency.symbol, product._id) : ''}
																												</span>
																											}
																										</div>
																									</div>
																								</Link>
																							)
																						}
																					}) : ''
																			}
																		</Row>
																	</Tab.Pane>
																)
															}
														})
														:
														<Fragment>
															<Nav.Item className="col-lg-2" >
																<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
																	<p>
																		<Skeleton count={6} />
																	</p>
																</SkeletonTheme>
															</Nav.Item>
															<Nav.Item className="col-lg-2" >
																<SkeletonTheme color="#202020" highlightColor="#7E9DF3">
																	<p>
																		<Skeleton count={6} />
																	</p>
																</SkeletonTheme>
															</Nav.Item>

														</Fragment>

													: ''
											}
										</Tab.Content>
									</Col>
								</Row>
							</div>
						</div>


					</Tab.Container>

				}


			</Container>

			<>


				<Modal show={show} onHide={handleClose} centered>
					<Modal.Header closeButton className="user-auth-modal-header">
						{/* <Modal.Title>Modal heading</Modal.Title> */}
					</Modal.Header>
					<Modal.Body className="user-auth-modal">
						{
							localStorage.getItem('attivitaEncryptedUser') ?
								<>
									<div className="d-flex justify-content-center mb-4">
										<FontAwesomeIcon icon={faWarning} className="warning-icon" />
									</div>
									{translations['Your account is not active. Kindly contact admin to verify your account.']}
								</>
								:
								<>
									<div className="d-flex justify-content-center mb-4">
										<FontAwesomeIcon icon={faWarning} className="warning-icon" />
									</div>
									{translations['here']} <Link to={`/login/${lang}`}>{translations['login']}</Link> {translations['go to login to see all product details']}
								</>
						}
					</Modal.Body>
				</Modal>
			</>
		</section>
	)

}


const mapStateToProps = state => ({
	errors: state.errors,
	product: state.product,
	siteSetting: state.siteSetting
});
export default connect(mapStateToProps, { beforeProducts, getProductsList, getVATforCountry, getCategories })(Product);