import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactDOm from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bannerImg from '../../assets/images/home-banner.svg'
import bannerImg2 from '../../assets/images/home-banner-2.png'
import { beforeBanner, getBanners } from "../banner/banner.action";
import "./HomeSlider.css";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import { Link } from 'react-router-dom';
import { getMenu, beforeCMS } from '../CMS/CMS.action'
import ReactPlayer from 'react-player'
const HomeSlider = (props) => {


	var url = require('url');

	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);
   const [sliderSpeed,setSliderSpeed]=useState(1000);

	useEffect(() => {
		if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
			translations.setLanguage(localStorage.getItem('language'));
			setLang(localStorage.getItem('language'))
		}
	}, [localStorage.getItem('language')])

	useEffect(() => {

       setSliderSpeed(props.siteSetting.settings?.sliderSpeed ? props.siteSetting.settings?.sliderSpeed*1000  :1000)
    }, [props.siteSetting.settings]);

	useEffect(() => {

        if (props.siteSetting.getSettings) {
           
        }
    }, [props.siteSetting.getSettings]);
	const [banners, setBanners] = useState([])
	var settings = {
		dots: true,
		infinite: true,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: sliderSpeed
	};
	useEffect(() => {
		props.beforeBanner();
		props.getBanners();
	}, []);
	useEffect(() => {
		if (props.banner.getBannerAuth) {
			setBanners(props.banner.banners)
		}
		props.beforeBanner();
	}, [props.banner.getBannerAuth]);


	return (
		<>
			{
				banners.length > 0 ?
					<Slider {...settings} className="home-banner-slider">
						{
							banners.map((banner, index) => {

								banner.description = banner.description.replace(/\s\s+/g, ' ')
								banner.description = banner.description.replace(/&nbsp;/g, ' ');

								banner.descriptionDE = banner.descriptionDE.replace(/\s\s+/g, ' ')
								banner.descriptionDE = banner.descriptionDE.replace(/&nbsp;/g, ' ');

								banner.title = banner.title.replace(/\s\s+/g, ' ')
								banner.title = banner.title.replace(/&nbsp;/g, ' ');

								banner.titleDE = banner.titleDE.replace(/\s\s+/g, ' ')
								banner.titleDE = banner.titleDE.replace(/&nbsp;/g, ' ');

								var ctaLink = url.parse(banner.CTAlink, true);

								let finalLink = ''
								ctaLink = ctaLink.host

								if (window.location.host == ctaLink || window.location.origin == ctaLink) {
									finalLink = banner.CTAlink + '/' + lang
								} else {
									finalLink = banner.CTAlink
								}

								return (

									<div className="home-banner custom-container" style={{ background: banner.color }} key={index}>
										<Container fluid style={{ background: banner.color }}>
											<Row className="align-items-center">
												<Col lg="6" md="12" xs="12">
													<div className="customBan banner-text">
														<div>
															<h1 style={{ color: banner.fontColor }}>{lang === 'de' ? banner.titleDE : banner.title}</h1>
															<p style={{ color: banner.fontColor }} dangerouslySetInnerHTML={{ __html: lang === 'de' ? banner.descriptionDE : banner.description }}></p>
															<hr></hr>
															{banner.CTAlink ?
																<Link to={{ pathname: finalLink }} target="_blank" className="my-account-login" >
																	<button type="button" className="account-text-btn">{lang === 'de' ? banner.CTAtextDE : banner.CTAtext} </button>
																</Link>
																:
																''

															}
														</div>
													</div>
												</Col>
												<Col lg="6" md="12" xs="12" className="mb-lg-0 mb-4 px-0">
													{
														banner.type == "video" && banner.url &&
														<div className="banner-img p-0 m-2">

															<ReactPlayer className="w-100 h-auto p-xl-5 p-lg-4" url={banner.url} playing={true} />

														</div>
													}
													{
														banner.type == "banner" && banner.banner &&
														<div className="banner-img sliderImg p-0 m-2">
															<img src={banner.banner} className="img-fluid" alt={banner.imageAltText} title="" />
														</div>
													}
												</Col>
											</Row>
										</Container>
									</div>
								)
							})
						}
					</Slider>
					
					: ''
			}
		</>
	);
}
const mapStateToProps = state => ({
	banner: state.banner,
    siteSetting: state.siteSetting,

})
export default connect(mapStateToProps, { beforeBanner, getBanners,getMenu })(HomeSlider)
