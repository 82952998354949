import React, { Component, Fragment, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Header from '../header/Header';
import AuthHeader from "../authheader/AuthHeader";
import Footer from '../footer/Footer';
import { ENV } from "../../config/config";
import { connect } from "react-redux";
import { beforeDashboard, getOrder } from "../dashboard/dashboard.action";
import { currencyFormat } from "../../utils/functions";
import moment from 'moment';

import LocalizedStrings from "react-localization";
import localizations from '../../localization';




import "./QuotationDetail.css";


const QuotationDetail = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(()=>{
		if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
		  translations.setLanguage(localStorage.getItem('language'));
		  setLang(localStorage.getItem('language'))
		}
	},[localStorage.getItem('language')])


	let history = useHistory()
	const [order, setOrder] = useState(null)

	useEffect(() => {
		props.getOrder(window.location.pathname.split('/')[2])
	}, []);
	useEffect(() => {
		if (props.dashboard.getOrderAuth) {
			setOrder(props.dashboard.data.order)
			props.beforeDashboard()
		}
	}, [props.dashboard.getOrderAuth]);
	let { customername } = ENV.getUserKeys();


	return (
		<Fragment>
			{customername ? <AuthHeader /> : <Header />}

			<div class="row dark-green div-breadcrumbs" style={{ background: 'rgb(146 132 250)', color: 'white', padding: '10px' }}>
				<div class="container">
					<div>
						<Link style={{ color: 'white', 'font-weight': '500' }} to={"/dashboard/"+lang}>{translations['Dashboard']}</Link> /
						{translations['Order Detail']} {order && order.order_nr}
					</div>
				</div>
			</div>
			<div id="main" className="custom-w-wrapper">
				{/* <Tabletop /> */}
				{
					order &&

					<section className="vouchers-main voucher-table custom-container mb-5">
						<Container fluid>
							<Row>
								<Col lg="12" md="12" xs="12">
									<div className="invoice-left-wrapper">
										<Row>
											<Col lg="6" md="6" xs="12">
												<span className="currency-holder">{currencyFormat(order.grandTotal)}</span>
											</Col>
											<Col lg="6" md="6" xs="12">
												<span className="text-capitalize pricing-holder">{translations['Sale order pricing']}</span>
											</Col>
										</Row>
									</div>
								</Col>
								<Col lg="12" md="12" xs="12">
									<div class="invoice-header">
										<h4>{translations['Sales Orders']}/{order.order_nr}</h4>
									</div>
									<div class="invoice-conatienr">
										<div className="info-wrapper mb-3">
											<p className="mb-2"><b>{translations['Order Date']}:</b> {moment(order.createdAt).format('MM-DD-YYYY')}</p>
											<h3>{translations['Invoicing and Shipping Address']}:</h3>
											<p>{order.shipping.address}</p>
										</div>
										{
											order.isInvoiced == true &&
											<div className="invoice-detail">
												<h6 className="font-weight-bold">{translations['Invoice']}</h6>
												<span onClick={() => history.push('/invoicedetail/' + order._id+"/"+lang)}>{order.invoice_nr} / {moment(order.invoicedAt).format('MM-DD-YYYY')}</span>
											</div>

										}
										<h5>{translations['Pricing']}</h5>
									</div>
									<div className="table-responsive">
										<table className="invoice-detail-table table table-striped attivita-table">
											<thead>
												<tr className="data-table-header">
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Products']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Quantity']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Unit Price']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Discount Percentage']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Amount']}</div>
													</th>
												</tr>
											</thead>
											<tbody>
												{
													order.items.map((item, index) => {
														return (
															<tr key={index}>
																<td>{item.item}</td>
																<td>{item.quantity}</td>
																<td>{currencyFormat(item.salesPrice)}</td>
																<td>{item.discountPercentage}%</td>
																<td>{currencyFormat(item.subTotal)}</td>
															</tr>
														)
													})
												}
												<tr className=" bg-transparent">
													<td colspan="3" className="text-right custom-bg"></td>
													<td colspan="" className="text-right order-left-formate"><b>{translations['Sub Total']}</b></td>
													<td className="text-left order-right-formate">{currencyFormat(order.subtotal)}</td>
												</tr>
												<tr className=" bg-transparent">
													<td colspan="3" className="text-right custom-bg"></td>
													<td colspan="" className="text-right order-left-formate"><b>{translations['Discount']}</b></td>
													<td className="text-left order-right-formate">{currencyFormat(order.discountTotal)}</td>
												</tr>
												<tr className=" bg-transparent">
													<td colspan="3" className="text-right custom-bg"></td>
													<td colspan="" className="text-right order-left-formate"><b>{order.vatLabel} ({order.vatPercentage}%)</b></td>
													<td className="text-left order-right-formate">{currencyFormat(order.taxtTotal)}</td>
												</tr>
												<tr className=" bg-transparent">
													<td colspan="3" className="text-right custom-bg"></td>
													<td colspan="" className="text-right order-left-formate"><b>{translations['Total']}</b></td>
													<td className="text-left order-right-formate">{currencyFormat(order.grandTotal)}</td>
												</tr>
												{order.paidAmount > 0 &&
													<tr className=" bg-transparent">
														<td colspan="3" className="text-right custom-bg"></td>
														<td colspan="" className="text-right order-left-formate"><b>{translations['Paid']}</b></td>
														<td className="text-left order-right-formate">{currencyFormat(order.paidAmount)}</td>
													</tr>
												}
												{order.refundedAmount > 0 &&
													<tr className=" bg-transparent">
														<td colspan="3" className="text-right custom-bg"></td>
														<td colspan="" className="text-right order-left-formate"><b>{translations['Refunded']}</b></td>
														<td className="text-left order-right-formate">{currencyFormat(order.refundedAmount)}</td>
													</tr>
												}
												{/* <tr>
													<td className="custom-tr-width text-left"></td>
													<td className="custom-tr-width text-right invoice-status-table" colspan="5">
														<table className="w-100">
															<tbody>


															</tbody>
														</table>
													</td>
												</tr> */}
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				}
			</div>
			<Footer />
		</Fragment>
	)
}


const mapStateToProps = state => ({
	dashboard: state.dashboard
})

export default connect(mapStateToProps, { beforeDashboard, getOrder })(QuotationDetail);