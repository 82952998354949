export const predefinedContent = {
    homeButton: "Home",
    shopButton: "Shop",
    aboutUsButton: "About Us",
    headerContactText: "Contact Us",
    emailVerifiedText: "Email Verified",
    emailNotVerifiedText: "Email Not Verified",
    AccountText: "Account",
    profileText: "Profile",
    logoutText: "Logout"
}