import React, { Component, Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Pagination, Button } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import Header from '../header/Header';
import AuthHeader from "../authheader/AuthHeader";
import Footer from '../footer/Footer';
import { connect } from "react-redux";
import { beforeDashboard, getOrder } from "../dashboard/dashboard.action";
import { currencyFormat } from "../../utils/functions";
import moment from 'moment';
import { ENV } from "../../config/config";


import LocalizedStrings from "react-localization";
import localizations from '../../localization';


import "./InvoiceDetail.css";


const InvoiceDetail = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(() => {
		if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
			translations.setLanguage(localStorage.getItem('language'));
			setLang(localStorage.getItem('language'))
		}
	}, [localStorage.getItem('language')])


	let history = useHistory()
	const [order, setOrder] = useState(null)

	useEffect(() => {
		props.getOrder(window.location.pathname.split('/')[2])
	}, []);
	useEffect(() => {
		if (props.dashboard.getOrderAuth) {
			setOrder(props.dashboard.data.order)
			props.beforeDashboard()
		}
	}, [props.dashboard.getOrderAuth]);
	let { customername } = ENV.getUserKeys();
	return (
		<Fragment>
			{customername ? <AuthHeader /> : <Header />}

			<div class="row dark-green div-breadcrumbs" style={{ background: 'rgb(146 132 250)', color: 'white', padding: '10px' }}>
				<div class="container">
					<div>
						<Link style={{ color: 'white', 'font-weight': '500' }} to={"/dashboard/" + lang}>{translations['Dashboard']}</Link> /
						<Link style={{ color: 'white', 'font-weight': '500' }} to={"/invoicelisting/" + lang}>{translations['Invoices']}</Link> /
						{translations['Invoice Detail']} {order && order.invoice_nr}
					</div>
				</div>
			</div>
			<div id="main" className="custom-w-wrapper">
				{/* <Tabletop /> */}
				{
					order &&

					<section className="vouchers-main voucher-table custom-container mb-5">
						<Container fluid>
							<Row>
								<Col lg="12" md="12" xs="12">
									<div className="invoice-left-wrapper">
										<Row className="align-items-center">
											<Col lg="6" md="6" xs="12">
												<span className="currency-holder">{currencyFormat(order.grandTotal)}</span>
											</Col>
											<Col lg="6" md="6" xs="12">
												<a href={ENV.invoicePath + 'invoices/' + order.invoice_nr + ".pdf"} target="_blank" className="pricing-holder pull-right" >
													<Button className="btn main-btn" variant="info">
														{translations['Download Invoice']}
													</Button>
												</a>
											</Col>
										</Row>
									</div>
								</Col>
								<Col lg="12" md="12" xs="12">
									<div class="invoice-header">
										<h4>{translations['Invoices']}/ {order.invoice_nr}</h4>
									</div>
									<div class="invoice-conatienr">
										<div className="info-wrapper mb-3">
											<h3>{translations['Invoicing and Shipping Address']}:</h3>
											<p>{order.shipping.address}</p>
										</div>
										<div className="invoice-detail">
											<ul className="list-unstyled">
												<li><b>{translations['Invoice Date']}:</b></li>
												<li>{moment(order.invoicedAt).format('MM-DD-YYYY')}</li>
												<li><b>{translations['Order']}#</b></li>
												<li>{order.order_nr}</li>
											</ul>

										</div>
									</div>
									<div className="table-responsive">
										<table className="invoice-detail-table table table-striped attivita-table">
											<thead>
												<tr className="data-table-header">
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Description']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Quantity']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Unit Price']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Discount Percentage']}</div>
													</th>
													<th scope="col">
														<div className="data-table-header-seperator">{translations['Amount']}</div>
													</th>
												</tr>
											</thead>
											<tbody>
												{
													order.items.map((item, index) => {
														return (
															<tr key={index}>
																<td>{item.item}</td>
																<td>{item.quantity}</td>
																<td>{currencyFormat(item.salesPrice)}</td>
																<td>{item.discountPercentage}%</td>
																<td>{currencyFormat(item.subTotal)}</td>
															</tr>
														)
													})
												}
												<tr>
													<td className="custom-tr-width text-left"></td>
													<td className="custom-tr-width text-right invoice-status-table" colspan="5">
														<table className="w-100">
															<tbody>
																<tr className=" bg-transparent">
																	<td colspan="3" className="text_right custom-bg"></td>
																	<td colspan="" className="text-right order-left-formate"><b>{translations['Sub Total']}</b></td>
																	<td className="text-left order-right-formate">{currencyFormat(order.subtotal)}</td>
																</tr>



																<tr className=" bg-transparent">
																	<td colspan="3" className="text-right custom-bg"></td>
																	<td colspan="" className="text-right order-left-formate"><b>{translations['Discount']}</b></td>
																	<td className="text-left order-right-formate">{currencyFormat(order.discountTotal)}</td>
																</tr>


																<tr className=" bg-transparent">
																	<td colspan="3" className="text-right custom-bg"></td>
																	<td colspan="" className="text-right order-left-formate"><b>{order.vatLabel} ({order.vatPercentage} %)</b></td>
																	<td className="text-left order-right-formate">{currencyFormat(order.taxtTotal)}</td>
																</tr>



																<tr className=" bg-transparent">
																	<td colspan="3" className="text-right custom-bg"></td>
																	<td colspan="" className="text-right order-left-formate"><b>{translations['Total']}</b></td>
																	<td className="text-left order-right-formate">{currencyFormat(order.grandTotal)}</td>
																</tr>


																<tr className=" bg-transparent">
																	<td colspan="3" className="text-right custom-bg"></td>
																	<td colspan="" className="text-right order-left-formate"><b>{translations['Payment Status']}</b></td>
																	<td className="text-left order-right-formate">
																		{
																			order.paidAmount == 0 ? translations['Un Paid'] : (order.paidAmount < order.grandTotal) ? translations['Partially Paid'] : translations['Paid']
																		}
																		{
																			order.refundedAmount > 0 ? order.refundedAmount == order.grandTotal ? " / " + translations["Refunded"] : " / " + translations["Partially Refunded"] : ""
																		}
																	</td>
																</tr>

																{
																	order.paidAmount > 0 &&
																	<tr className=" bg-transparent">
																		<td colspan="3" className="text-right custom-bg"></td>
																		<td colspan="" className="text-right order-left-formate"><b>{translations['Total Paid']}</b></td>
																		<td className="text-left order-right-formate">{currencyFormat(order.paidAmount)}</td>
																	</tr>


																}
																{
																	order.paidAmount > 0 &&
																	<tr className=" bg-transparent">
																		<td colspan="3" className="text-right custom-bg"></td>
																		<td colspan="" className="text-right order-left-formate"><b>{translations['Remaining Amount']}</b></td>
																		<td className="text-left order-right-formate">{currencyFormat(order.grandTotal - order.paidAmount)}</td>
																	</tr>
																}
																{
																	order.refundedAmount > 0 &&
																	<tr className=" bg-transparent">
																		<td colspan="3" className="text-right custom-bg"></td>
																		<td colspan="" className="text-right order-left-formate"><b>{translations['Refunded Amount']}</b></td>
																		<td className="text-left order-right-formate">{currencyFormat(order.refundedAmount)}</td>
																	</tr>
																}
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				}
			</div>
			<Footer />
		</Fragment>
	)
}

const mapStateToProps = state => ({
	dashboard: state.dashboard
})

export default connect(mapStateToProps, { beforeDashboard, getOrder })(InvoiceDetail);