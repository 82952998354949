import React, { Component, useState , useEffect} from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./ProductBtn.css";
import { transitions } from "react-alert";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';


const ProductBtn = (props) => {

	const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'de')
  

	const [lang, setLang] = useState(localStorage.getItem('language')?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(()=>{
		if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
		  translations.setLanguage(localStorage.getItem('language'));
		  setLang(localStorage.getItem('language'))
		  setLanguage(localStorage.getItem('language'))
		}
	},[localStorage.getItem('language')])

	return (
				<Row className="justify-content-center product-btn">
					<Link to={"/shop/"+lang} className="view-main-btn text-center">
						<button type="button" className="main-btn">{translations["View All Products"]}</button>
					</Link>
				</Row>
            )
    }


export default ProductBtn;