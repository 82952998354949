import React, { useRef, Component, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col} from 'react-bootstrap';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { beforeProducts, searchProducts } from '../product/product.action'
import { variationCurrencyFormat, currencyFormat } from "../../utils/functions"
import LocalizedStrings from "react-localization";
import localizations from '../../localization';
import "./Search.css";


const Search = (props) => {


	const searchDropdown = useRef(null)
	const [closeSearch, setCloseSearch] = useState(false); 


	const [searchQuery, setSearchQuery] = useState('')
	const [sort, setSort] = useState()
	const [searchResults, setSearchResults] = useState([])
	const [currency, setCurrency] = useState({
		symbol: "€",
		code: "EUR"
	})


	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	
	useEffect(() => {
		document.addEventListener('mousedown',closeOpenSearch)
	}, []);
	
	useEffect(()=>{
		if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
		  translations.setLanguage(localStorage.getItem('language'));
		  setLang(localStorage.getItem('language'))
		}
	},[localStorage.getItem('language')])


	const closeOpenSearch = (e)=>{



		if(searchDropdown.current && closeSearch == false && !searchDropdown.current.contains(e.target)){
			setCloseSearch(false)
			// alert('haala')

		}
	}

	const searchProducts = () => {
		if(searchQuery || sort)
			props.searchProducts(searchQuery, sort)
		setSearchResults([])
	}

	useEffect(() => {
		if(props.product.getsearchProducts){
			setSearchResults(props.product.products)
			props.changeSelected(props.product.products);
			props.beforeProducts()
		}
	}, [props.product.getsearchProducts]);


	
	useEffect(() => {
		if(props.product.getsearchProducts){

			if(props.product.products.length > 0) {
				setCloseSearch(true)
			}
		}
	}, [props.product.getsearchProducts]);
	
	useEffect(() => {
		searchProducts()
	}, [searchQuery, sort]);


	return (
		<Container>
			<div className="shop-searching" >
				<div className="shop-search-wrapper m-auto" >
					<div className="shop-searching-box wrapInput">
					<div className="inputBg">
					<div id="search-field" >
						<input type="search" className="form-control" onInput={(e) => {
								setSearchQuery(e.target.value)
							}
						} placeholder={translations['Search']} />
					</div>
					<div id="search-icon" >
						<FontAwesomeIcon icon={faMagnifyingGlass }/>
					</div>
					</div>
					<div>
					<div className="shop-search-dropdown" >
						<Dropdown>
							<Dropdown.Toggle variant="default" id="dropdown-basic">
								<span>
									{
										sort == 1 ? translations['Price: Asc'] : ''
									}
									{
										sort == 2 ? translations['Price: Desc'] : ''
									}
									{
										sort == 3 ? translations['Name: Asc'] : ''
									}
									{
										sort == 4 ? translations['Name: Desc'] : ''
									}
									{
										!sort ? translations['Sort by'] : ''
									}
								</span>
							</Dropdown.Toggle>
							<Dropdown.Menu className="customMenu">
							<ul>
								<li><Link className="dropdown-item" to="#" onClick={()=>setSort(1)}>{translations['Price: Asc']}</Link></li>
								<li><Link className="dropdown-item" to="#" onClick={()=>setSort(2)}>{translations['Price: Desc']}</Link></li>
								<li><Link className="dropdown-item" to="#" onClick={()=>setSort(3)}>{translations['Name: Asc']}</Link></li>
								<li><Link className="dropdown-item" to="#" onClick={()=>setSort(4)}>{translations['Name: Desc']}</Link></li>
							</ul>
							</Dropdown.Menu>
						</Dropdown>
					</div></div>
					</div>
					{/* {props.click && searchResults && searchResults.length > 0 ? */}
					{searchResults && searchResults.length > 0 && closeSearch == true ?
						
						
						<div id="search-result"  ref={searchDropdown} >
							<ul>
								{
									searchResults.map( (searchResult, index) => {
										return (
											<li>
												<Link to={"/productdetail/"+searchResult._id+"/"+localStorage.getItem("language")} className="link-unstyled col-lg-12" >
													{searchResult.name} 
													<span className="float-right"> Starting from {currencyFormat(searchResult.initialPrice,currency.code,currency.symbol)}</span>
												</Link>
											</li>
										)
									})
								}
							</ul>
						</div>
						:''
					
					
					
					}
					
					
				</div>
			</div>
		</Container>
	)
}

const mapStateToProps = state => ({
	errors: state.errors,
	product: state.product
});
export default connect(mapStateToProps, { beforeProducts, searchProducts})(Search);