import React, { Component, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap';
import AboutBannerImg from '../../assets/images/about-us-banner.png'
import "./AboutUsBanner.css";

import LocalizedStrings from "react-localization";
import localizations from '../../localization';


const AboutUsBanner = (props) => {

    const [lang, setLang] = useState(localStorage.getItem('language')?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

    useEffect(()=>{
		if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
		  translations.setLanguage(localStorage.getItem('language'));
		  setLang(localStorage.getItem('language'))
		}
	},[localStorage.getItem('language')])

    return (
        <section className="about-us-banner custom-container">
            <Container fluid>
                <Row className="align-items-center">
                    <Col lg="6" md="6" xs="12">
                        <div className="banner-text">
                            <h1>{translations["ABOUT US"]}</h1>
                            <p>{translations["WE CYBER-GUARD YOUR DATA, DEVICES & E-COMMUNICATIONS"]}</p>
                        </div>
                    </Col>
                    <Col lg="6" md="6" xs="12">
                        <div className="banner-img">
                            <img src={AboutBannerImg} className="img-fluid" alt="about-us-banner" title="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}


export default AboutUsBanner;