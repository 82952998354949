import React, { Component, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./Cyber.css";
import LocalizedStrings from "react-localization";
import localizations from '../../localization';

const Cyber = (props) => {

	const [lang, setLang] = useState(localStorage.getItem('language')?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(()=>{
		if(localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de'){
		  translations.setLanguage(localStorage.getItem('language'));
		  setLang(localStorage.getItem('language'))
		}
	},[localStorage.getItem('language')])

	return (
			<section className="about-us-banner-bottom custom-container">
				<Container fluid>
					<h2>{translations["Our heart"]}</h2>
					<p><b>{translations["Our visions"]}</b> {translations["Our vision"]}</p>
					<span>{translations["Our business"]}</span>
				</Container>
		</section>

		)
}


export default Cyber;