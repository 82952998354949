import React, { Component, Fragment, useState, useEffect } from "react";
import ReactDOm from 'react-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import productOffcImg from '../../assets/images/products-office.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import productImg2 from '../../assets/images/products-device.png'
import { getVATforCountry } from "../siteSettings/siteSettings.action";

import { ENV } from "../../config/config";
import { variationCurrencyFormat, currencyFormat } from "../../utils/functions"
import { beforeCart, validateCoupon, addToUserCart } from "./cart.action";
import "./Cart.css";
import swal from 'sweetalert'
import LocalizedStrings from "react-localization";
import localizations from '../../localization';


const Cart = (props) => {
	const [vatPercentage, setVatPercentage] = useState(props.siteSetting.getCountry ? props.siteSetting.vat : props.siteSetting.getSettings ? props.siteSetting.settings.vatPercentage : 19)
	const [vatLabel, setVatLabel] = useState('VAT')
	const [inValidCoupon, setInValidCoupon] = useState(false)
	let userData = ENV.getUserKeys();
	const [currency, setCurrency] = useState({
		symbol: "€",
		code: "EUR"
	})
	const [cartTotals, setCartTotals] = useState(JSON.parse(localStorage.getItem("cartTotal")) ? JSON.parse(localStorage.getItem("cartTotal")) : {
		subTotal: 0,
		discount: 0,
		tax: 0,
		total: 0,
	})
 
	const [couponCodeMsg, setCouponCodeMsg] = useState(false)
	const [couponCode, setCouponCode] = useState(JSON.parse(localStorage.getItem("coupon")) ? JSON.parse(localStorage.getItem("coupon")).promotionCode : '')
	const [coupon, setCoupon] = useState(JSON.parse(localStorage.getItem("coupon")) ? JSON.parse(localStorage.getItem("coupon")) : {})
	const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : [])
	const [firststate, setFirststate] = useState(true)

	useEffect(() => {
		setCouponCodeMsg(false)
		resetCoupon()
		props.beforeCart()
		props.validateCoupon({ customerId: userData._id })
		props.beforeCart()
	}, [])
	useEffect(() => {
		if (props.siteSetting.getSettings) {
			if (userData?.addresses && userData?.addresses?.length > 0) {
				props.getVATforCountry(userData?.addresses[0]?.country)
			}
			else {
				setVatPercentage(props.siteSetting.settings.vatPercentage)
				setVatLabel("VAT")
			}
		}
	}, [props.siteSetting.getSettings]);

	useEffect(() => {

		if (props.siteSetting.getCountry) {
			
			setVatLabel(props.siteSetting.vat_label)
			setVatPercentage(props.siteSetting.vat)
		}
	}, [props.siteSetting.getCountry]);

	useEffect(() => {
		console.log("🚀 ~ file: Cart.js:74 ~ useEffect ~ props.cart.validateCouponAuth:", props.cart.validateCouponAuth)
		if (props.cart.validateCouponAuth) {
			console.log("🚀 ~ file: Cart.js:81 ~ useEffect ~ props.cart.coupon:", props.cart.coupon)

			if (!props.cart.coupon.priceList?.customer || (props.cart.coupon.priceList?.customer && props.cart.coupon.priceList?.customer?.includes(userData._id))) {

				setCoupon(props.cart.coupon.priceList)
				setInValidCoupon(false)
			} else {

				setCoupon({})
				if(props.cart.coupon?.show){
					setInValidCoupon(false)
				}else{
				setInValidCoupon(true)

				}
				
			}
			props.beforeCart()
		}
	}, [props.cart.validateCouponAuth]);

	useEffect(() => {
		if (props.cart.inValidateCouponAuth) {
			setInValidCoupon(true)
			resetCoupon()
			localStorage.setItem("coupon", JSON.stringify({}))
			props.beforeCart()
		}
	}, [props.cart.inValidateCouponAuth]);

	useEffect(() => {

		if (coupon && coupon.rules) {
			applyCouponToCart()
			updateCart()
			localStorage.setItem("coupon", JSON.stringify(coupon))
		}
		else {
			resetCoupon()
			localStorage.setItem("coupon", JSON.stringify({}))
		}
	}, [coupon]);

	const incrementQuantity = (index) => {
		let localCart = cart
		let cartQuantity = localCart[index].quantity + 1;


		if (cartQuantity <= localCart[index]?.totalLicenses) {
			localCart[index].quantity += 1

			localStorage.setItem('cart', JSON.stringify(localCart))
			updateCart()
			setCart([...localCart])
		} else {
			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});
		}

	}
	const bulkIncrementOrDecrement = (index, bulkQuantity) => {
		let localCart = cart
		let cartQuantity = localCart[index].quantity + bulkQuantity;
		localCart[index].quantity = bulkQuantity;
		if (cartQuantity <= localCart[index]?.totalLicenses) {
			localStorage.setItem('cart', JSON.stringify(localCart))
			updateCart()
			setCart([...localCart])
		} else {
			swal({
				text: translations['License for this variant will be available soon'],
				icon: "warning",
				button: "OK!",
			});
		}

	}
	const decremenetQuantity = (index) => {
		let localCart = cart
		localCart[index].quantity -= 1
		if (localCart[index].quantity == 0) {
			localCart.splice(index, 1)
		}
		localStorage.setItem('cart', JSON.stringify(localCart))
		updateCart()
		setCart([...localCart])

	}
	const removeProduct = (index) => {
		let localCart = cart
		localCart[index].quantity = 0
		if (localCart[index].quantity == 0) {
			localCart.splice(index, 1)
		}
		localStorage.setItem('cart', JSON.stringify(localCart))
		updateCart()
		setCart([...localCart])

	}
	const checkCoupon = () => {
		if (!couponCode) {
			setInValidCoupon(false)
			setCouponCodeMsg(true)
		}
		if (couponCode) {
			setCouponCodeMsg(false)
			resetCoupon()
			props.beforeCart()
			props.validateCoupon({ promotionCode: couponCode,customerId: userData._id })
			
		} else {
			resetCoupon()
		}
	}

	const applyCouponToCart = () => {
		let localCart = cart
		localCart.map((item, cartIndex) => {
			let discountPercentage = 0
			let discountAmount=0
			if (coupon) {
				coupon?.rules?.map((rule, ruleIndex) => {
					if (rule.minQty <= item.quantity || rule.minQty == undefined) {
						const startDate = new Date(rule.startDate);
						const endDate = new Date(rule.endDate);
						const currentDate = new Date();
						// Compare the dates
						//1=Fixed 0=percentage
						if (currentDate >= startDate && currentDate <= endDate) {
							if (rule.type === 1) // If the rule is applicable on all products
							{
								if(rule.discountType==0){
									discountPercentage = rule.percentage
								}else{
									discountAmount=rule.percentage
								}
								return
							}
							else if (rule.type == 2) // If the rule is applicable on specific product
							{
								if (item.productId == rule.productId) {
									if(rule.discountType==0){
										discountPercentage = rule.percentage
									}else{
										discountAmount=rule.percentage
									}
									return
								}
							}
							else if (rule.type == 3) // If the rule is applicable on specific product and variation
							{
								if (item.productId == rule.productId && item.variationId == rule.productVariationId) {
									if(rule.discountType==0){
										discountPercentage = rule.percentage
									}else{
										discountAmount=rule.percentage
									}
									return
								}
							}
						}
					}
				})
			}
			item.discountPercentage = discountPercentage
			item.discountAmount = discountAmount

		})
		localStorage.setItem('cart', JSON.stringify(localCart))
		setCart([...localCart])

	}

	const resetCoupon = () => {
		localStorage.removeItem("coupon")	
		let localCart = cart
		localCart=localCart.map((item, cartIndex) => {
		delete	item.discountPercentage 
		delete	item.discountAmount
	     return item
		})
		localStorage.setItem('cart', JSON.stringify(localCart))
		updateCart()
		setCart([...localCart])
	}

	const updateCart = () => {
		applyCouponToCart()
		let subTotal = 0
		let discount = 0
		let tax = 0
		let total = 0

		cart.map((item, index) => {
			let subTotal_ = item.quantity * item.salesPrice		//	SalesPrice * Quantity
			console.log("🚀 ~ file: Cart.js:263 ~ cart.map ~ item:", index, item, subTotal_)
			
			subTotal += subTotal_
			if(item.discountPercentage){
				let discountPercentage = item.discountPercentage ? item.discountPercentage : 0	// DiscountPercentage
				let discountedPrice = subTotal_ - (subTotal_ * discountPercentage / 100)	// (SalesPrice * Quantity) - Discount
				let taxAmount = (discountedPrice * vatPercentage / 100)
	
				discount += subTotal_ * discountPercentage / 100
				item.price = discountedPrice + taxAmount
				tax += taxAmount
			}else if(item.discountAmount){
				let discountAmount = item.discountAmount ? item.discountAmount : 0	// discountAmount	
				let discountedPrice = subTotal_ - discountAmount	// (SalesPrice * Quantity) - Discount
				let taxAmount = (vatPercentage / 100) * discountedPrice;
	
				discount += discountAmount*item.quantity
				item.price = discountedPrice + taxAmount
				tax += taxAmount
			}else if(item.discountAmount==0 && item.discountPercentage==0){
				let taxAmount = (vatPercentage / 100) * subTotal_;
				tax += taxAmount
			}
			
		})
		total = subTotal - discount + tax

		setCartTotals({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		})
		localStorage.setItem('cartTotal', JSON.stringify({
			subTotal: subTotal,
			discount: discount,
			tax: tax,
			total: total
		}))

		if (userData?._id) {	//If user is logged in 
			cart.map((item, index) => {
				cart[index].unitSalesPrice = cart[index].salesPrice
				cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity
			})

			let cartParams = {
				userId: userData._id,
				isCheckout: false,
				items: cart
			}

			if (firststate) {
				setFirststate(false)
			} else {
				props.addToUserCart(cartParams)
			}
		}

	}

	const [lang, setLang] = useState(localStorage.getItem('language') ?? 'de');
	let translations = new LocalizedStrings(localizations);
	translations.setLanguage(lang);

	useEffect(() => {
		if (localStorage.getItem('language') == 'en' || localStorage.getItem('language') == 'de') {
			translations.setLanguage(localStorage.getItem('language'));
			setLang(localStorage.getItem('language'))
		}
	}, [localStorage.getItem('language')])
	return (
		<section className="cart-wrapper">
			<Container>
				<Row>
					<Col lg="12" xs="12" className="mb-5">
						<div className="card card-info">

							<div className="card-body">
								<div className="cart-product-box">
									{
										cart && cart.length > 0 ?
											cart.map((item, index) => {
												return (

													<Row className="cart_row" key={index}>
														<Col lg="12" md="12" sm="12">
															<Row>
																<Col lg="2" md="2" sm="2">
																	<div className="product-img-wrap">
																		<Link target="_blank" title="View product" to="#.">
																			<img className="img-responsive" src={item.image ? item.image : productImg2} />
																		</Link>
																	</div>
																</Col>
																<Col lg="8" md="8" sm="8">
																	<p className="product-name ml-2 mb-1 checkout-p">
																		<strong title="Product name">{item.name} {item.variationName}</strong>

																	</p>
																	{/* { item.licenses < item.quantity ?
																	<p className="product-name ml-2">
																	
																		<span class="price low-license-des"> { item.licenses < item.quantity ? 'low license' : ''}</span>
																		
																	</p>
																	:
																	''} */}
																</Col>
																<Col lg="2" md="2" sm="2">
																	<div className="dlt-btn-wrap">
																		<button type="button" className="btn btn-link btn-xs" onClick={() => removeProduct(index)}>
																			<FontAwesomeIcon icon={faXmark} />
																		</button>
																	</div>
																</Col>
															</Row>
														</Col>
														<Col lg="12" md="12" sm="12">
															<div className="quantity-price-wrapper mt-3 mb-3">

																<div className="quantity-div cart-quilty-incremnt">
																	<button type="button" className="decrement" id="" title="Decrease quantity" onClick={() => decremenetQuantity(index)}>
																		<FontAwesomeIcon icon={faMinus} />
																	</button>
																	<input title="Quantity" type="text" maxlength="4" max="9999" size="1" value={item.quantity}
																		className="qty-input form-control" onChange={(event) => {
																			bulkIncrementOrDecrement(index, event.target.value != "" ? parseInt(event.target.value) : 1)
																		}} />
																	<button type="button" className="increment" id="" title="Increase quantity" onClick={() => incrementQuantity(index)}>
																		<FontAwesomeIcon icon={faPlus} />
																	</button>
																</div>
																<div className="text-right">
																	<h6 title="Unit price">
																		<strong>
																			{/* {currencyFormat( item.salesPrice + (item.salesPrice * vatPercentage / 100)  ,currency.code,currency.symbol)} */}
																			{currencyFormat(item.salesPrice, currency.code, currency.symbol)} &nbsp;
																			<span className="text-muted" >x</span>&nbsp;
																			{item.quantity}
																		</strong>
																	</h6>

																</div>
															</div>
														</Col>
													</Row>
												)
											})
											:
											<Row className="cart_row">
												<Col lg="12" md="12" sm="12">
													{
														localStorage.getItem('attivitaEncryptedUser') ?

															<p className="product-name text-center">
																{translations['Nothing in the cart. Click']} <Link to={"/shop/" + lang} >{translations['here']}</Link> {translations['to add product to cart']}
															</p>
															:
															<p className="text-center">
																{translations['You are not logged in to the system. Kindly']} <Link to={`/login/${lang}`}>{translations['login']}</Link> {translations['to continue']}
															</p>

													}

												</Col>
											</Row>
									}
								</div>


								 <Row id="couponArea">
								
									<Col md="12" xs="12">
									
										<h6 className="">{translations['Have a coupon']}</h6>
									</Col>
								   {console.log('inValidCoupon inValidCoupon',inValidCoupon)}
									<Col md="12" xs="12">
										<input type="text" className="cart-form-control mb-2" value={couponCode} onChange={(event) => setCouponCode(event.target.value)} id="coupon-code-text" placeholder={translations['Enter Coupon Code']} />
										{(inValidCoupon && couponCode) && <small class="error">	{translations['Coupon is invalid']}</small>}
										{couponCodeMsg && <small class="error">	{translations['Coupon is required']}</small>}

										<button type="button" className="btn btn-success cart-btn-block" onClick={() => checkCoupon()}>
											{translations['Apply Coupon']}
										</button>

									</Col>
								</Row> 
							</div>
							<div className="card-footer checkout-card-footer">
								<Row className="text-center">
									<Col lg="12" xs="12">

										<h6 className="text-right cart-h6">{translations['Sub Total']}
											<strong id="subtotal_price" className="ml-2">
												{currencyFormat(cartTotals.subTotal, currency.code, currency.symbol)}
											</strong>
										</h6>
										<h6 className="text-right cart-h6">{translations['Discount']}
											<strong id="subtotal_price" className="ml-2">
												{currencyFormat(cartTotals.discount, currency.code, currency.symbol)}
											</strong>
										</h6>
										<h6 className="text-right cart-h6">{vatLabel} {vatPercentage}%
											<strong id="vat_total_price" className="ml-2">
												{currencyFormat(cartTotals.tax, currency.code, currency.symbol)}
											</strong>
										</h6>
										<h6 className="text-right cart-h6">{translations['Total']}
											<strong id="total_price" className="ml-2">
												{currencyFormat(cartTotals.total, currency.code, currency.symbol)}
											</strong>
										</h6>
									</Col>
									<Col lg="12" ms="12" sm="12" className="px-1">
										{cart && cart.length > 0 &&
											<div id="checkoutButton" className="mt-3">
												<Link to={"/checkout/" + lang} >
													<button type="button" className="btn btn-success btn-block" >
														{translations['Checkout']}
													</button>
												</Link>
											</div>
										}
									</Col>

								</Row>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

const mapStateToProps = state => ({
	siteSetting: state.siteSetting,
	cart: state.cart,
})
export default connect(mapStateToProps, { beforeCart, validateCoupon, addToUserCart, getVATforCountry })(Cart);