import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Landing from './components/landing/Landing';
import Shop from './components/shop/Shop';
import AboutUs from './components/aboutus/AboutUs';
import ContactUs from './components/contactus/ContactUs';
import ProductDetail from './components/productdetail/ProductDetail';
import Cart from './components/cart/Cart';
import Checkout from './components/checkout/Checkout';

import DashboardCard from './components/dashboardcard/DashboardCard';
import Voucher from './components/voucher/Voucher';
import VoucherTable from './components/vouchertable/VoucherTable';
import TablePagination from './components/tablepagination/TablePagination';
import Login from './components/login/Login';
import Register from './components/register/Register';
import Tabletop from './components/tabletop/Tabletop';

import Imprint from './components/imprint/Imprint';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import TermsOfUse from './components/termsofuse/TermsOfUse';
import NotFound from './components/notfound/NotFound';
import BuyProduct from './components/buyproduct/BuyProduct';
import EmailVerified from "./components/register/EmailVerified";
import AuthHeader from "./components/authheader/AuthHeader";
import ForgetPassword from "./components/login/ForgetPassword";
import ResetPassword from "./components/login/ResetPassword";

import { PrivateRoute } from '../src/privateRoute';
import "../src/App.css";
import routes from '../src/privateroutes/privateroutes'
import CMS from './components/CMS/CMS';
import Template from './components/template';
import TemplateAbout from './components/templateabout';
const App = (props) => {
  const [lang, setLang] = useState(null)

  useEffect(() => {
    let url = window.location.href
    let lang = url.split('/').pop()
    if (lang == 'en' || lang == 'de') {
      localStorage.setItem("language", lang)
    } else {
      getGeo()
    }
  }, []);

  const getGeo = async () => {
    if (localStorage.getItem('language')) {

      setLang(localStorage.getItem('language'))
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            const request = await fetch("https://ipinfo.io/json?token=d4aca20a95c4a6")
            const jsonResponse = await request.json()

            if (jsonResponse.country == "DE") {
              localStorage.setItem("language", "de")
              setLang('de')
            } else {

              localStorage.setItem("language", "en")
              setLang('en')

            }
          },
          function (error) {
            localStorage.setItem("language", "de")
            setLang('de')
          }
        );
      }

    }
  }
  return (
    <Router>
      <Switch>
        <Route exact path='/verify-email/:userId/:de' component={EmailVerified} />
        {/* <Route exact path='/:de' component={Landing} /> */}
        <Route exact path='/:de' component={Landing} />
        <Route exact path='/shop/:de' component={Shop} />
        <Route exact path='/aboutus/:de' component={AboutUs} />
        <Route exact path='/contactus/:de' component={ContactUs} />
        <Route exact path='/productdetail/:id/:de' component={ProductDetail} />
        <Route exact path='/cart/:de' component={Cart} />
        <Route exact path='/checkout/:de' component={Checkout} />
        <Route exact path='/template/:en' component={Template} />
        <Route exact path='/templateabout/:en' component={TemplateAbout} />
        {/* <Route exact path='/dashboard' component={Dashboard} /> */}
        <Route exact path='/dashboardcard/:de' component={DashboardCard} />
        <Route exact path='/voucher/:de' component={Voucher} />
        <Route exact path='/vouchertable/:de' component={VoucherTable} />
        <Route exact path='/tablepagination/:de' component={TablePagination} />
        <Route exact path='/login/:de' component={Login} />
        <Route exact path='/register/:de' component={Register} />
        {/* <Route exact path='/quotationlisting' component={QuotationListing} />
          <Route exact path='/salesorderlisting' component={SalesOrdersListing} />
          <Route exact path='/invoicelisting' component={InvoicesListing} /> */}
        <Route exact path='/tabletop/:de' component={Tabletop} />
        {/* <Route exact path='/quotationdetail/:id' component={QuotationDetail} />
          <Route exact path='/invoicedetail/:id' component={InvoiceDetail} /> */}
        <Route exact path='/imprint/:de' component={Imprint} />
        <Route exact path='/privacypolicy/:de' component={PrivacyPolicy} />
        <Route exact path='/termsofuse/:de' component={TermsOfUse} />
        <Route exact path='/notfound/:de' component={NotFound} />
        {/* <Route exact path='/userProfile' component={UserProfile} /> */}

        <Route exact path='/forget-password/:de' component={ForgetPassword} />
        <Route exact path='/reset-password/:de' component={ResetPassword} />
        <Route exact path='/buyproduct/:de' component={BuyProduct} />
        {/* <Route exact path='/thankyou/:id' component={Thankyou} /> */}
        <Route exact path='/authheader' component={AuthHeader} />
        <Route exact path='/page/:slug/:de' component={CMS} />


        {
          routes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={true}
              access={true}
              component={props => (
                <route.component {...props} />
              )}
            />

          ))

        }

        {lang ? <Redirect to={`/${lang}`} /> : ''}


      </Switch>
    </Router>
  )
}

export default App;